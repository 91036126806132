package csaware.infoshare

import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessageStrategy
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.InformationShare
import dk.rheasoft.csaware.api.ShareState
import dk.rheasoft.csaware.api.UserRole
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.addInputClassModifier
import kafffe.bootstrap.form.textArea
import kafffe.core.CssClassModifier
import kafffe.core.Model
import kafffe.core.StyleModifier
import kafffe.core.addClass
import kafffe.messages.Messages.Companion.formatDateTime

class InformationShareEditDlg(share: InformationShare) :
        FormDialog<InformationShare>(Model.of("${share.id}"), Model.of(share)) {

    init {
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1000px"
        })
        addClass("hgap-3")
        labelStrategy = csawareMessageStrategy("informationshare_")
        modal.modifiersContent.add(CssClassModifier("bg-info"))
        treeForm(model.data)
    }

    fun accept() {
        model.data.state = ShareState.SharingAccepted
        onSubmitOk()
        detach()
    }

    fun decline() {
        model.data.state = ShareState.SharingDenied
        onSubmitOk()
        detach()
    }

    private fun FormComponentConsumer<InformationShare, InformationShare>.treeForm(infoShare: InformationShare) {

        row {
            col(ColWidth(ResponsiveSize.md, 8)) {

                textArea(InformationShare::summary).apply {
                    readOnly = true
                    lines = 3
                }.addInputClassModifier("csaware-field")
                model.data.data.forEachIndexed() { index, shareData ->
                    addChild(InformationShareDataEditor(Model.of(shareData)))
                }
            }
            col(ColWidth(ResponsiveSize.md, 4)) {
                addClass("hgap-3")
                readonly( i18nText(CsawareMessages::informationshare_create), Model.of(infoShare.created.formatDateTime())).addInputClassModifier("csaware-field")
                if (UserInformation.hasRole(UserRole.SystemAdministrator)) {
                    submit("accept", this@InformationShareEditDlg::accept).apply {
                        color = BasicColor.success
                        iconClasses = "fas fa-thumbs-up"
                    }

                    submit("decline", this@InformationShareEditDlg::decline).apply {
                        color = BasicColor.danger
                        iconClasses = "fas fa-thumbs-down"
                    }
                }
                cancel().color = BasicColor.secondary
            }
        }
    }
}