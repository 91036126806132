package csaware.translation

import kafffe.core.Model
import kotlin.browser.localStorage

object AutoTranslationModel : Model<Boolean>() {
    val key = "autotranslate"

    override var data: Boolean
        get() {
        return localStorage.getItem(key) ?.toBoolean() ?: true
        }
        set(value) {
            localStorage.setItem(key, value.toString())
            changed()
        }
}