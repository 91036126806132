package csaware.comm

import api.*
import dk.rheasoft.csaware.api.*
import org.w3c.dom.WebSocket
import org.w3c.dom.events.Event
import org.w3c.xhr.XMLHttpRequest
import parseThreatObservation
import parseThreatObservationResult
import parseThreatOverview
import parseTranslationText
import parseUser
import parseUserResult
import toJson
import kotlin.browser.window

open class CSAwareBackend(val exceptionHandler: (errorCode: Int, errorText: String) -> Unit) {
    class WebSocketReconnect(val endpoint: String, val messageReceiver: (String) -> Unit) {
        val reconnectInterval = 10 * 1000

        init {
            open()
        }

        fun open() {
            try {
                println("WebSocket open")
                WebSocket(endpoint).apply {
                    onmessage = {
                        messageReceiver(it.data as String)
                    }

                    onerror = {
                     // IGNORE -  REOPEN ONLY ONCLOSE   reopenHandler()
                    }
                    onclose = {
                        reopenHandler()
                    }

                }
            } catch (e: Throwable) {
                window.setTimeout({ open() }, reconnectInterval)
            }
        }

        private fun reopenHandler() {
            println("WebSocket connection error/closed - reconnect attempt in a few seconds")
            window.setTimeout({ open() }, reconnectInterval)
        }

    }

    companion object {
//        val loc = window.location
//        var serverEndpoint = """${loc.protocol}//${loc.host}""" // kafffenv.csawareBackend
//        var serverWsEndpoint = """${if (loc.protocol == "https:") "wss" else "ws"}://${loc.host}/updates"""

        val loc = window.location
        val context = if (loc.pathname.startsWith("/cs-aware", ignoreCase = true)) "/cs-aware" else ""
        var serverEndpoint = """${loc.protocol}//${loc.host}$context""" // kafffenv.csawareBackend
        var serverWsEndpoint = """${if (loc.protocol == "https:") "wss" else "ws"}://${loc.host}$context/updates"""

        var updateListeners = mutableListOf<(msg: String) -> Unit>()

        init {
            WebSocketReconnect(serverWsEndpoint) {
                for (l in updateListeners) {
                    l(it)
                }
            };
        }
    }

    var requestHeaders = mapOf(
            "Content-Type" to "application/json"
    )

    fun getThreatGroups(receiver: (Set<String>) -> Unit) {
        getTxt("/threats/groups") {
            val x: Array<String> = JSON.parse(it)
            receiver(x.toSet())
        }
    }

    fun getThreatsCurrentActive(atTime: Timestamp, offset: Int, pagesize: Int, receiver: (QueryResult<ThreatObservation>) -> Unit) {
        getTxt("/threats/active?activeAt=${atTime.toJsonFormat()}&offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = parseThreatObservationResult(it)
            receiver(x)
        }
    }

    fun getThreatsOverview(atTime: Timestamp, receiver: (ThreatOverview) -> Unit) {
        getTxt("/threats/overview?activeAt=${atTime.toJsonFormat()}") {
            val x: ThreatOverview = parseThreatOverview(it)
            receiver(x)
        }
    }

    fun getThreatsCurrentActiveWithFilter(atTime: Timestamp, filter: ThreatFilter, offset: Int, pagesize: Int, receiver: (QueryResult<ThreatObservation>) -> Unit) {
        getTxt("/threats/active?activeAt=${atTime.toJsonFormat()}" +
                "&group=${filter.groupSearchValue()}" +
                "&assignee=${filter.assigneeSearchValue()}" +
                "&where=${filter.whereSearchValue()}" +
                "&offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = parseThreatObservationResult(it)
            receiver(x)
        }
    }

    fun getThreatsRecentClosed(offset: Int, pagesize: Int, receiver: (QueryResult<ThreatObservation>) -> Unit) {
        getTxt("/threats/closed?offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = parseThreatObservationResult(it)
            receiver(x)
        }
    }

    fun getThreatsRecentClosedOfGroup(filter: ThreatFilter, offset: Int, pagesize: Int, receiver: (QueryResult<ThreatObservation>) -> Unit) {
        getTxt("/threats/closed" +
                "?group=${filter.groupSearchValue()}" +
                "&assignee=${filter.assigneeSearchValue()}" +
                "&where=${filter.whereSearchValue()}" +
                "&offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = parseThreatObservationResult(it)
            receiver(x)
        }
    }

    fun changeThreatObservationState(threatObservationId: String, state: StateHistory, receiver: (ThreatObservation) -> Unit) {
        sendJsonTxt("/threats/$threatObservationId/statehistory", "POST", state.toJson()) {
            val x: ThreatObservation = parseThreatObservation(it)
            receiver(x)
        }
    }

    fun threatAssign(threatObservationId: String, assigneeMail: String, receiver: (ThreatObservation) -> Unit) {
        sendJsonTxt("/threats/$threatObservationId/assign", "POST", assigneeMail) {
            val x: ThreatObservation = parseThreatObservation(it)
            receiver(x)
        }
    }

    fun getThreatObservedDataObjects(threatObservationId: String, receiver: (List<ObservedDataObject>) -> Unit) {
        getTxt("/threats/$threatObservationId/observedData") {
            val x: List<ObservedDataObject> = parseObservedDataObjects(it)
            receiver(x)
        }
    }

    fun getSystemResourceOverview(atTime: Timestamp, receiver: (ThreatOverview) -> Unit) {
        getTxt("/threats/systemresource/overview?activeAt=${atTime.toJsonFormat()}&limit=1000") {
            val x: ThreatOverview = parseThreatOverview(it)
            receiver(x)
        }
    }

    fun getSystemResourceThreatsCurrentActive(atTime: Timestamp, systemResourceId: String, offset: Int, pagesize: Int, receiver: (QueryResult<ThreatObservation>) -> Unit) {
        getTxt("/threats/systemresource/active?activeAt=${atTime.toJsonFormat()}&id=$systemResourceId&offset=$offset&limit=$pagesize") {
            val x: QueryResult<ThreatObservation> = parseThreatObservationResult(it)
            receiver(x)
        }
    }

    fun getCurrentUser(receiver: (User) -> Unit) {
        getTxt("/user/info") {
            val x: User = parseUser(it)
            receiver(x)
        }
    }

    fun getUsers(offset: Int, pagesize: Int, receiver: (QueryResult<User>) -> Unit, filter: String) {
        getTxt("/user/users?offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<User> = parseUserResult(it)
            receiver(x)
        }
    }

    fun getUsersByRole(offset: Int, pagesize: Int, role: UserRole, receiver: (QueryResult<User>) -> Unit) {
        getTxt("/user/usersByRole?offset=$offset&limit=$pagesize&role=$role") {
            val x: QueryResult<User> = parseUserResult(it)
            receiver(x)
        }
    }

    fun updateUser(user: User, receiver: (User) -> Unit) {
        sendJsonTxt("/user/update", "POST", user.toJson()) {
            val x: User = parseUser(it)
            receiver(x)
        }
    }

    fun deleteUser(user: User, receiver: () -> Unit) {
        sendJsonTxt("/user/delete/${user.email}", "DELETE", user.toJson()) {
            receiver()
        }
    }

    fun updateInformationShare(data: InformationShare, @Suppress("UNUSED_PARAMETER") receiver: () -> Unit) {
        sendJsonTxt("/informationshare/decision/update", "POST", data.toJson() ){
            receiver()
        }
        println(data.toJson())
    }

    fun getInformationShares(offset: Int, pageSize: Int, receiver: (QueryResult<InformationShare>) -> Unit) {
        getTxt("/informationshare/decisions?offset=$offset&limit=$pageSize") {
            val x: QueryResult<InformationShare> = parseInformationShareResult(it)
            receiver(x)
        }
    }

    fun getInformationShareCount(receiver: (Int) -> Unit) {
        getTxt("/informationshare/count") {
            val x: Int = it.toInt()
            receiver(x)
        }
    }

    fun translate(data: TranslationText, receiver: (translation : TranslationText) -> Unit) {
        sendJsonTxt("/translate", "POST", data.toJson() ){
            val translation = parseTranslationText(it)
            receiver(translation)
        }
    }

//    fun getSystemDependencies(receiver: (SystemDependency) -> Unit) {
    fun getSystemDependencies(receiver: (List<SystemDependencyResource>) -> Unit) {
        getTxt("/sysdep/all") {
            receiver(parseSystemDependencyResourceList(it))
        }
    }

    fun getSystemDependency(id: String, receiver: (SystemDependencyResource?) -> Unit) {
        getTxt("/sysdep/read/$id") {
            receiver(parseSystemDependencyResource(it))
        }
    }

    fun storeSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/sysdep/update", "POST", resource.toJson()) {
            receiver(parseSystemDependencyResource(it))
        }
    }

    fun deleteSystemDependency(resource: SystemDependencyResource, receiver: (SystemDependencyResource?) -> Unit) {
        sendJsonTxt("/sysdep/delete/${resource.id}", "DELETE", resource.toJson()) {
            receiver(resource)
        }
    }

    fun importSystemDependencies(jsonString: String, replaceCurrent: Boolean, receiver: () -> Unit) {
        sendJsonTxt("/sysdep/import?replace=$replaceCurrent", "POST", jsonString) {
            receiver()
        }
    }

    fun getSystemDependencyConfig(receiver: (SystemDependencyConfig) -> Unit) {
        getTxt("/sysdep/config") {
            receiver(parseSystemDependencyConfig(it))
        }
    }

    fun storeSystemDependencyConfig(config: SystemDependencyConfig, receiver: (SystemDependencyConfig) -> Unit) {
        sendJsonTxt("/sysdep/config", "POST", config.toJson()) {
            receiver(parseSystemDependencyConfig(it))
        }
    }

    fun sendMail(mail: MailSimple, receiver: (String) -> Unit) {
        sendJsonTxt("/mail/send", "PUT", mail.toJson()) {
            receiver(it)
        }
    }

    fun getTxt(path: String, bodyReceiver: (body: String) -> Unit) {
        val req = XMLHttpRequest();
        with(req) {
            onerror = { event -> handleError(this, event) }
            onloadend = { event ->
                println("Response: ${responseText}")
                when (status) {
                    in (200..299) -> bodyReceiver(responseText)
                    else -> handleError(this, event)
                }
            }
            open("GET", "${serverEndpoint}${path}")
            for (header in requestHeaders) {
                setRequestHeader(header.key, header.value)
            }

        }
        req.send()
    }

    fun sendJsonTxt(path: String, method: String, json: String, bodyReceiver: (body: String) -> Unit) {
        val req = XMLHttpRequest();
        with(req) {
            onerror = { event -> handleError(this, event) }
            onloadend = { event ->
                println("Response: ${responseText}")
                when (status) {
                    in (200..299) -> bodyReceiver(responseText)
                    else -> handleError(this, event)
                }
            }
            open(method, "${serverEndpoint}${path}")
            for (header in requestHeaders) {
                setRequestHeader(header.key, header.value)
            }

        }
        req.send(json)
    }

    open fun handleError(request: XMLHttpRequest, event: Event) {
        if (request.status.toInt() == 0 && request.statusText.isNullOrEmpty()) {
            exceptionHandler(0, "Unable to communicate with backend server")
        } else {
            if (request.status.toInt() in setOf(401, 403)) {
                window.location.replace("${serverEndpoint}/login")
            } else {
                exceptionHandler(request.status.toInt(), request.statusText)
            }
        }
    }
}