package csaware.systemdepend.config

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import org.w3c.dom.HTMLElement

class TagsEditor(val labelModel: Model<String>, model: Model<List<String>>, val countFunction: () -> Map<String, Int> ) : KafffeComponentWithModel<List<String>>(model), FormValueProvider {

    val currentTags: MutableList<String> = model.data.toMutableList()

    override fun updateValueModel() {
        model.data = currentTags.toList()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        val count: Map<String, Int> = countFunction()

        return div {
            addClass("form-group")
            val tagWidth = "13rem"
            label { text(labelModel.data) }
            div {
                addClass("list-group")
                currentTags.forEach { tag ->
                    div {
                        addClass("input-group")
                        div {
                            addClass("form-control list-group-item")
                            withElement {
                                style.width = tagWidth
                                style.maxWidth = tagWidth
                                setAttribute("readOnly", "true")
                            }
                            text(tag)
                            val usageCount = count[tag] ?: 0
                            if (usageCount > 0) {
                                sup {
                                    addClass("badge badge-pill badge-warning")
                                    text("$usageCount")
                                }
                            }
                        }
                        span {
                            addClass("input-group-append")
                            button {
                                addClass("btn btn-secondary")
                                withElement {
                                    type = "button"
                                    onclick = {
                                        currentTags.remove(tag)
                                        rerender()
                                    }
                                }

                                i {
                                    addClass("fas fa-trash")
                                }

                            }
                        }
                    }

                }

                div {
                    addClass("input-group mt-1")
                    val tagInput = input {
                        addClass("form-control list-group-item")
                        withElement {
                            type = "text"
                            value = ""
                            style.width = tagWidth
                            style.maxWidth = tagWidth
                        }
                    }.element!!
                    span {
                        addClass("input-group-append")
                        button {
                            addClass("btn btn-secondary")
                            withElement {
                                type = "button"
                                onclick = {
                                    val newTag = tagInput.value
                                    if (newTag.isNotBlank() && newTag !in currentTags) {
                                        currentTags.add(newTag)
                                        rerender()
                                    }
                                }
                            }
                            i {
                                addClass("fas fa-plus")

                            }
                        }
                    }
                }
            }
        }
    }

}