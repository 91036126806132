package csaware.threats

import api.ObservedDataObject
import csaware.mail.MailEditDlg
import dk.rheasoft.csaware.api.*
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessageStrategy
import csaware.messages.i18nText
import csaware.systemdepend.MarkdownRender
import csaware.translation.TranslationField
import kafffe.bootstrap.*
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.addInputClassModifier
import kafffe.bootstrap.form.editSelectSingle
import kafffe.bootstrap.form.textArea
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.bootstrap.navigation.NavSimpleContainer
import kafffe.core.*
import kafffe.messages.Messages.Companion.formatDateTime

class ThreatStateChangeDlg(observation: ThreatObservation) :
        FormDialog<ThreatObservation>(Model.of("${observation.name}: ${observation.id}"), Model.of(observation)) {
    val newState = StateHistory(
            time = timestampNow(),
            state = observation.state,
            description = "",
            initiator = UserInformation.current.email
    )

    val assigneeModel: Model<String?> = Model.ofNullable(observation.assignee)
    val assigneesModel: Model<List<String>> = Model.ofGet {
        val admins: List<String> = UserInformation.systemAdministrators.data.map{it.email}
        listOf("") + admins
    }

    val courseOfActionTable = BootstrapTable.create<CourseOfAction>(observation.courseOfActions) {
        applyDefaultStyle()
        modifiers.add(CssClassModifier("csaware-field"))
        col(i18nText(CsawareMessages::name), { Label(it.name) })
        col(i18nText(CsawareMessages::description), { Label(it.description).preformatted() })
        col(i18nText(CsawareMessages::action), {Label(it.action)})
    }

    val histTable = BootstrapTable.create<StateHistory>(observation.stateHistory) {
        applyDefaultStyle()
        modifiers.add(CssClassModifier("csaware-field"))
        col(i18nText(CsawareMessages::timestamp), { Label(it.time.formatDateTime()) })
        col(i18nText(CsawareMessages::threat_state), { Label(CsawareMessages.get().threatState(it.state)) })
        col(i18nText(CsawareMessages::threat_state_initator), { Label(it.initiator) })
        col(i18nText(CsawareMessages::threat_state_comment), { Label(it.description).preformatted() })
    }

    val observedDataObjectModel : Model<List<ObservedDataObject>> = Model.of(listOf())
    val observedDataObjectTable = BootstrapTable.create<ObservedDataObject>(observedDataObjectModel) {
        applyDefaultStyle()
        modifiers.add(CssClassModifier("csaware-field"))
        modifiers.add(StyleModifier{ padding = "0px" })

        col(i18nText(CsawareMessages::threat_observed_data_type), { Label(it.type) })
        col(i18nText(CsawareMessages::threat_observed_data_id), { Label(it.id) })
        col(i18nText(CsawareMessages::threat_observed_data_data), {
            val observedDataObject = it
            KafffeComponent.ofKafffeHtml {
                table {
                    addClass("csaware_observed_data")
                    tr {
                        observedDataObject.fieldNames().forEach { fieldName ->
                            th {
                                text(fieldName)
                            }
                        }
                    }
                    tr {
                        observedDataObject.fieldNames().forEach {fieldName ->
                            td {
                                text(observedDataObject[fieldName].toString()/*.take(40)*/)
                            }
                        }

                    }
                }
            }
        })
    }
    val tabs = NavSimpleContainer().apply {
        add("history", i18nText(CsawareMessages::threat_state_history), "fas fa-history") { histTable }
        add("course_of_action", i18nText(CsawareMessages::threat_course_of_action), "fas fa-key") { courseOfActionTable }
        add("observed_data_objects", i18nText(CsawareMessages::threat_observed_data_objects), "fas fa-binoculars") { observedDataObjectTable }
    }

    private val descriptionModel: Model<String> = model.func({ m ->
        if (m.data.socialMedia?.title.isNullOrBlank())
            m.data.descriptionSoMe
        else
            "# ${ m.data.socialMedia?.title}\n\n${m.data.descriptionSoMe}"
    })
    private val descriptionField: TranslationField = TranslationField(descriptionModel.data)
    private val mdRender = MarkdownRender(Model.of(""))

    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        // clear any tootltip,popovers
        BootstrapTooltipModifier.remove()
        BootstrapPopoverModifier.remove()
        CsawareServices.backend.getThreatObservedDataObjects(model.data.id, {observedDataObjectModel.data = it })
    }


    init {
        loadData()
        labelStrategy = csawareMessageStrategy("threat_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "90vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "95vw"
            width = "95vw"
        })
        modal.modifiersContent.add(CssClassModifier("bg-info"))
        // We need some hgap because we do not apply whitespace "\n" between buttons.
        addClass("hgap-3")
        addClass("vgap-3")
        addChild(mdRender)
        mdRender.apply {
            addClass("csaware-field")
            modifiers.add(StyleModifier{
                display = if (model.data.isNotBlank()) "block" else "none"
            })
        }
        row {
            col(ColWidth(ResponsiveSize.md, 8)) {
                row {
                    col(ColWidth(ResponsiveSize.md, 4)) {
                        val stixTypeModel = model.func({ CsawareMessages.get().threatStixType(it.data.stixType) })
                        readonly(i18nText(CsawareMessages::threat_stixtype), stixTypeModel).addInputClassModifier("csaware-field")
                    }
                    col(ColWidth(ResponsiveSize.md, 4)) {
                        readonly(ThreatObservation::threatGroup).addInputClassModifier("csaware-field")
                    }
                    col(ColWidth(ResponsiveSize.md, 4)) {
                        val whereModel = model.func({ m -> CsawareServices.systemDependencies.whereIdsToName(m) })
//                        readonly(i18nText(CsawareMessages::threat_where), whereModel).addInputClassModifier("csaware-field")
                        addChild (
                                KafffeComponent.ofKafffeHtml {
                                    div {
                                        addClass("form-group")
                                        label { text(i18nText(CsawareMessages::threat_where).data) }

                                        val whereLink = object : WhereLink(model.data.whereSightedRefs) {
                                            override fun onclick(name: String) {
                                                this@ThreatStateChangeDlg.detach()
                                                super.onclick(name)
                                            }
                                        }
                                        whereLink.addClass("form-control csaware-field")
                                        add(whereLink.html)
                                    }
                                }
                        )
                    }
                }
                addChild(Label(labelStrategy.label("description")))

                addChild(BootstrapButton(Model.of(""), {toggleMarkdownPreview()}).apply {
                    iconClasses = "fas fa-eye"
                    color = BasicColor.info
                })
                descriptionField.apply {
                    lines = 12
                    modifiers.add(StyleModifier{height = "24rem"})
                }
                addChild(descriptionField)
            }
            val awaitHealingDecision = model.data.state == ThreatState.HealingAwaitDecision
            val healingInProgress = model.data.state == ThreatState.Healed || model.data.state == ThreatState.HealingInProgress
            col(ColWidth(ResponsiveSize.md, 4), subModel = Model.of(newState)) {
                addClass("hgap-3")
                if (!awaitHealingDecision) {
                    legend("newState")
                    // readonly(i18nText(CsawareMessages::timestamp), Model.of(model.data.time.formatDateTime()))
                    val st = ThreatStateDropdown("st", i18nText(CsawareMessages::threat_state), model.property(StateHistory::state))
                    addChild(st)
                } else {
                    legend("selfHealingConfirm ")
                    newState.description = this@ThreatStateChangeDlg.model.data.stateHistory[0].description
                }
                textArea("comment", labelStrategy.label("state_comment"),  model.property(StateHistory::description)).apply {
                    required = true
                    lines = 4
                }
                input(StateHistory::initiator).apply { readOnly = true }
                editSelectSingle("assignee", i18nText(CsawareMessages::threat_assignee), assigneeModel, assigneesModel).apply {
                    readOnly = !UserInformation.hasRole(UserRole.SystemAdministrator)
                }
                if (awaitHealingDecision) {
                    if (UserInformation.hasRole(UserRole.SystemAdministrator)) {
                        submit("accept", this@ThreatStateChangeDlg::accept).apply {
                            color = BasicColor.success
                            iconClasses = "fas fa-thumbs-up"
                        }
                        submit("decline", this@ThreatStateChangeDlg::decline).apply {
                            color = BasicColor.danger
                            iconClasses = "fas fa-thumbs-down"
                        }
                        submit("update", this@ThreatStateChangeDlg::save).apply {
                            color = BasicColor.info
                            iconClasses = "fas fa-save"
                        }
                    }
                    cancel().color = BasicColor.secondary
                } else if (healingInProgress) {
                    if (UserInformation.hasRole(UserRole.SystemAdministrator)) {
                        submit("save", this@ThreatStateChangeDlg::save).apply {
                            color = BasicColor.success
                            iconClasses = "fas fa-thumbs-up"
                        }
                    }
                    cancel().color = BasicColor.secondary
                } else {
                    if (UserInformation.hasRole(UserRole.SystemAdministrator)) {
                        submit().color = BasicColor.primary
                    }
                    cancel().color = BasicColor.secondary
                }
            }
        }
        addChild(tabs)
        tabs.navigateTo(NavigationPath.fromString("container/history"))
    }

    private fun toggleMarkdownPreview() {
        if (mdRender.model.data.isNullOrBlank()) {
            mdRender.model.data = descriptionField.translation
        } else {
            mdRender.model.data = ""
        }
        mdRender.rerender()
    }

    private fun save() {
        onSubmitOk()
        detach()
    }

    fun accept() {
        newState.state = ThreatState.HealingAccepted
        onSubmitOk()
        detach()
    }

    fun decline() {
        newState.state = ThreatState.HealingDeclined
        onSubmitOk()
        detach()
    }

    private fun changeState(observation: ThreatObservation, newState: StateHistory, assignee: String) {
        CsawareServices.alerts.clearAlerts()
        CsawareServices.backend.changeThreatObservationState(observation.id, newState) {
            val reassigned = (observation.assignee != assignee)
            if (reassigned) {
                observation.assignee = assignee
                CsawareServices.backend.threatAssign(observation.id, assignee) {}
                if (assignee.isNotBlank() /* TODO and !me */) {
                    MailEditDlg.showThreatAssignDialog(assignee, observation, newState)
                }
            }
            with(observation.stateHistory) {
                clear()
                addAll(it.stateHistory)
            }
            rerenderRecursive()
        }
    }

    companion object {
        fun show(observation: ThreatObservation) {
            ThreatStateChangeDlg(observation).apply {
                onSubmitOk = {
                    changeState(observation, newState, assigneeModel.data ?: "")
                }
                attach()
            }
        }

    }
}