package csaware.systemdepend

import api.*
import dk.rheasoft.csaware.api.ThreatObservation
import dk.rheasoft.csaware.api.UserRole
import csaware.main.CsawareServices
import csaware.main.UserInformation
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel

class SystemDependencyService() {

    val model: Model<List<SystemDependencyResource>> = RefreshingCacheModel<List<SystemDependencyResource>>(::refreshModel, listOf())
    val config: Model<SystemDependencyConfig> = RefreshingCacheModel<SystemDependencyConfig>(::refreshConfig, SystemDependencyConfig(), timeToLiveSeconds = 60 * 60)

    public fun expireModels() {
        (model as RefreshingCacheModel).expire()
        (config as RefreshingCacheModel).expire()
    }
//    private fun refreshModel(model: Model<SystemDependency>) {
    private fun refreshModel(model: Model<List<SystemDependencyResource>>) {
        if (UserInformation.hasAnyRole(UserRole.SystemAdministrator, UserRole.Viewer)) {
            CsawareServices.backend.getSystemDependencies { model.data = it }
        } else {
            model.data = listOf()
        }
    }

    private fun refreshConfig(model: Model<SystemDependencyConfig>) {
        if (UserInformation.hasAnyRole(UserRole.SystemAdministrator, UserRole.Viewer)) {
            CsawareServices.backend.getSystemDependencyConfig { model.data = it }
        } else {
            model.data = SystemDependencyConfig()
        }
    }


    fun refresh() {
        refreshModel(model)
    }

    fun refreshConfig() {
        refreshConfig(config)
    }

    fun byId(id: String): SystemDependencyResource? = model.data.find { it.id == id }

    fun byName(name: String): SystemDependencyResource? = model.data.find { it.name == name }

    /** reverse connectsTo returns list of ids*/
    fun connectedFrom(systemResource: SystemDependencyResource): List<String> =
            connectedFromById(systemResource.id)

    /** reverse connectsTo returns list of ids*/
    fun connectedFromById(resourceId: String) : List<String> =
            model.data.filter { it.source.contains(resourceId) }.map { it.id }

    fun namesFromIds(ids: Iterable<String>): List<String> = ids.map(::byId).filterNotNull().map { it.name }

    fun whereIdsToName(m: Model<ThreatObservation>): String {
        return namesFromIds(m.data.whereSightedRefs).joinToString(", ")
    }

    fun store(systemResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
        config.data.removeUndefinedFields(systemResource)
        CsawareServices.backend.storeSystemDependency(systemResource, { refreshWithSelected(systemResource.id, selectedModel) })
    }

    fun delete(systemResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
        CsawareServices.backend.deleteSystemDependency(systemResource, { refreshWithSelected(systemResource.id, selectedModel) })
    }

    fun import(jsonString: String, replaceCurrent: Boolean = true) {
        CsawareServices.backend.importSystemDependencies(jsonString, replaceCurrent, { refresh(); refreshConfig() })
    }

    private fun refreshWithSelected(id: String, selectedModel: Model<SystemDependencyResource>) {
        CsawareServices.backend.getSystemDependencies {
            model.data = it
            (byId(id) ?: SystemDependencyResource.NULL).let{selectedModel.data = it}
        }

    }

    fun fieldById(key: String): SystemDependencyField =
            config.data.fields.find {it.id == key} ?: SystemDependencyField(key, key, FieldType.STRING)

    fun storeConfig(data: SystemDependencyConfig) {
        CsawareServices.backend.storeSystemDependencyConfig(data){
            config.data = it
            removeUndefinedFieldsAndTags()
        }
    }

    private fun removeUndefinedFieldsAndTags() {
        val definedFieldIds = config.data.fields.map{ it.id}.toSet()
        val definedTags = config.data.valueSets[SystemDependencyResource.infoflowValueSet]?.toSet() ?: setOf()
        for (resource in model.data) {
            val undefinedFields = resource.data.keys.filter{it !in definedFieldIds}
            val undefinedTags = resource.x_infoflow.filter { it !in definedTags}
            if (undefinedFields.isNotEmpty() || undefinedTags.isNotEmpty()) {
                undefinedFields.forEach { resource.data.remove(it) }
                undefinedTags.forEach { resource.x_infoflow.remove(it) }
                CsawareServices.backend.storeSystemDependency(resource) {}
            }
        }
        (model as RefreshingCacheModel).expire()
    }

}