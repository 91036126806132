package csaware.threats

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.ThreatObservation
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.core.KafffeHtml

object Popovers {
    fun description(desc: () -> String): BootstrapPopoverModifier {
        return BootstrapPopoverModifier().apply {
            with(options) {
                title = i18nText(CsawareMessages::description).data
                content = desc
                delay = 0
                template = infoTemplate()
                container = "body"
                trigger = "click hover focus"
                boundary = "viewport"
            }
        }
    }

    fun infoTemplate(): String {
        return KafffeHtml.start.div {
            addClass("popover")
            div { addClass("arrow") }
            h3 { addClass("popover-header bg-info text-dark") }
            div {
                addClass("popover-body bg-dark text-white")
                element?.style?.apply {
                    maxHeight = "50vh"
                    overflowX = "hidden"
                    overflowY = "hidden"
                }
            }
        }.element!!.outerHTML
    }

    fun showInfo (titleText: String, desc: () -> String): BootstrapPopoverModifier     {
        return BootstrapPopoverModifier().apply {
            with(options) {
                this.placement = "bottom"
                title = titleText
                content = desc
                delay = 0
                template = infoTemplate()
                container = "body"
                trigger = "click hover focus"
                boundary = "viewport"
            }
        }
    }
}
