package csaware.systemdepend

import api.SystemDependencyConfig
import api.SystemDependencyResource
import csaware.get
import csaware.main.CsawareServices
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.overview.UserConfiguration
import dk.rheasoft.csaware.api.ThreatOverview
import dk.rheasoft.csaware.api.UserRole
import kafffe.core.*
import mxgraph.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlin.browser.window
import kotlin.dom.addClass

class SystemGraph(resourcesModel: Model<List<SystemDependencyResource>>, val threatSummary: Model<ThreatOverview>) : KafffeComponentWithModel<List<SystemDependencyResource>>(resourcesModel) {
    val titleLabel = addChild(Label(i18nText(CsawareMessages::system_dependencies)))

    val selectionModel: Model<SystemDependencyResource> = Model.of(SystemDependencyResource.NULL)
    val searchBox = addChild(SystemSearch(selectionModel, resourcesModel))

    val hightlightModel: Model<String> = Model.of(Companion.SELECTION_HIGHLIGHT)

    private var graph: StaticMxGraph? = null

    private var resources: List<SystemDependencyResource> by delegateToModel()

    val graphConfig: SystemDependencyConfig
        get() = CsawareServices.systemDependencies.config.data

    // An optional  user specified node id used for layout
    var currentUserRootId: String? = null
        set(value) {
            field = value
            graph?.doLayout()
        }

    private fun cellToResource(cell: mxCell) = resources.find { it.id == cell.getId() }

    private fun idToCell(id: String): mxCell? {
        val vertices = graph?.getChildCells(graph?.getDefaultParent()!!, true, false)
        return vertices?.find { it.id == id }
    }

    val onThreatsChanged = ModelChangeListener {
        graph?.applyStyleToAllElements()
    }

    val onSelectionHighlightChanged = ModelChangeListener {
        graph?.makeSelectionVisible()
        graph?.applyStyleToAllElements()
    }

    override fun attach() {
        super.attach()
        threatSummary.listeners.add(onThreatsChanged)
        hightlightModel.listeners.add(onSelectionHighlightChanged)
        selectionModel.listeners.add(onSelectionHighlightChanged)
        CsawareServices.systemDependencies.config.listeners.add(onModelChanged)
    }

    override fun detach() {
        super.detach()
        graph?.let {
            it.destroy()
            graph = null
        }
        threatSummary.listeners.remove(onThreatsChanged)
        hightlightModel.listeners.remove(onSelectionHighlightChanged)
        selectionModel.listeners.remove(onSelectionHighlightChanged)
        CsawareServices.systemDependencies.config.listeners.remove(onModelChanged)
    }

    fun riskStyle(resourceId: String): String {
        val severity = threatSummary.data[resourceId].severityMax
        return "severity-$severity"
    }

    fun updateGraph() {
        val invisibleEdges = mutableListOf<mxCell>()
        val doubleArrows = mutableListOf<mxCell>()
        if (resources.isNotEmpty()) {
            graph?.let {
                val g = it
                g.popupMenuHandler.hideMenu()
                try {
                    val p = g.getDefaultParent()
                    g.getModel().beginUpdate()
                    g.removeCells(g.getChildCells(g.getDefaultParent(), true, true))

                    val idToVertex = mutableMapOf<String, mxCell>()
                    val pairToEdge = mutableMapOf<Pair<String, String>, mxCell>()


                    for (resource in resources) {
                        val vertex = g.insertVertex(p, resource.id, resource.name, 0.0, 0.0, 80.0, 30.0, nodeTypeToShape(resource.data["x_csaware_node_type"]) +";vertice_normal_color")
                        idToVertex[resource.id] = vertex
//                        g.updateCellSize(vertex, true)
                    }
                    for (resource in resources) {
                        for (connect in resource.source) {
                            val fromCell = idToVertex[resource.id]
                            var toCell = idToVertex[connect]
                            if (toCell == null) {
                                // Hack missing data in graph
                                toCell = g.insertVertex(p, connect, connect, 0.0, 0.0, 80.0, 30.0, "edge")
                                idToVertex[connect] = toCell
//                                g.updateCellSize(toCell, true)
                            }
                            var doubleEdge: mxCell?
                            doubleEdge = doesEdgeExist(fromCell, toCell, pairToEdge)
                            if (doubleEdge != null) {
                                val edge: mxCell = g.insertEdge(p, resource.id + "->" + connect, "", fromCell!!, toCell)
                                pairToEdge.put(Pair(fromCell.id, toCell.id), edge)
                                invisibleEdges.add(edge)
                                doubleArrows.add(doubleEdge)
                            } else {
                                val edge: mxCell = g.insertEdge(p, resource.id + "->" + connect, "", fromCell!!, toCell)
                                pairToEdge.put(Pair(fromCell.id, toCell.id), edge)
                            }
//                            g.insertEdge(p, resource.id + "->" + connect, "", fromCell!!, toCell)
                        }
                    }
                } catch (a: Throwable) {
                    println(a)
                } finally {
                    g.setInvisibleEdges(invisibleEdges)
                    g.setDoubleArrowEdges(doubleArrows)
                    g.applyStyleToAllElements()
                    g.getModel().endUpdate()
                    g.doLayout()
                    // Delayed to make sure the outline is refreshed after config changes.
                    window.setTimeout({ g.outline.update(true) }, 300)
                }
            }
        }
    }

    fun doesEdgeExist(from: mxCell?, to: mxCell?, pairToEdge: Map<Pair<String, String>, mxCell>): mxCell? {
        if (from == null || to == null) {
            return null
        }
        if (pairToEdge.containsKey(Pair(from.id, to.id))) return pairToEdge.get(Pair(from.id, to.id))
        if (pairToEdge.containsKey(Pair(to.id, from.id))) return pairToEdge.get(Pair(to.id, from.id))
        return null
    }

    fun addEdgeToMap(name: String, map: MutableMap<String, MutableList<mxCell>>, element: mxCell) {
        map.getOrPut(name, { mutableListOf<mxCell>() }).add(element)
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        var graphElement: HTMLElement? = null
        var outlineElement: HTMLElement? = null
        graph?.let {
            it.destroy()
        }
        return div {
            div {
                addClass("card")
                div {
                    addClass("h4")
                    addClass("card-header csaware-field text-light")
                    add(titleLabel.html.also { it.addClass("mr-auto") })
                    div {
                        addClass("btn-group float-right")
                        add(searchBox.html)
                    }
                }
                div {
                    withElement {
                        addClass("bg-secondary")
                        style.apply {
                            position = "relative"
                            width = "100%"
                            height = "85vh"
                        }
                    }
                    div {
                        withElement {
                            graphElement = this
                            style.apply {
                                width = "100%"
                                height = "100%"
                                padding = "1em"
                                overflowX = "hidden"
                                overflowY = "hidden"
                            }
                        }
                    }
                    div {
                        withElement {
                            id = "outlineContainer"
                            outlineElement = this
                            style.apply {
                                zIndex = "1"
                                position = "absolute"
                                overflowX = "hidden"
                                overflowY = "hidden"
                                bottom = "8px"
                                right = "8px"
                                width = "20%"
                                height = "17vh" // 20% of 85vh
                                borderStyle = "solid"
                                backgroundColor = "rgba(108, 117, 125, 0.7)"
                                borderColor = "darkgrey"
                            }
                        }
                    }

                    // need both div for graph and outline
                    graphElement?.let {
                        graph = StaticMxGraph(it, outlineElement!!)
                        mxEvent.disableContextMenu(it)
                    }
                    zoomButtons()
                    updateGraph()
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.zoomButtons() {
        val btnClass = "btn btn-sm btn-secondary"
        div {
            addClass("btn-group")
            element?.style?.apply {
                border = "1px darkgrey solid"
                zIndex = "2"
                position = "absolute"
                top = "8px"
                right = "8px"
            }
            button {
                addClass(btnClass)
                i { addClass("fas fa-search-minus fa-2x") }
                onClick { graph?.zoomOut() }
            }
            button {
                addClass(btnClass)
                span {
                    addClass("fa-stack")
                    i { addClass("fas fa-search fa-stack-2x") }
                    i { addClass("far fa-square fa-stack-1x") }
                }
                onClick { graph?.fit() }
            }
            button {
                addClass(btnClass)
                i { addClass("fas fa-search-plus fa-2x") }
                onClick { graph?.zoomIn() }
            }
        }
    }

    fun nodeTypeToShape(nodeType: String?): String {
        return nodeType?.let{graphConfig.shapeMap[it]} ?: "rectangle_shape"
    }

    fun panToSelection() {
        if (graph != null && isRendered) {
            graph?.makeSelectionVisible()
        } else {
            window.setTimeout({panToSelection()}, 500)
        }
    }

    inner class StaticMxGraph(val graphElement: HTMLElement, val outlineElement: HTMLElement) : mxGraph(graphElement) {
        var outline: mxOutline
        private var layout: mxHierarchicalLayout
        var highlight: mxCellHighlight
        var invisibleEdges = mutableListOf<mxCell>()
        var doubleArrorEdges = mutableListOf<mxCell>()

        fun setInvisibleEdges(invisibleEdgeList: MutableList<mxCell>) {
            invisibleEdges = invisibleEdgeList
        }

        fun setDoubleArrowEdges(doubleArrowList: MutableList<mxCell>) {
            doubleArrorEdges = doubleArrowList
        }

        override fun isCellSelectable(cell: Any): Boolean {
            return cell is mxCell && cell.isVertex()
        }

        override fun isCellMovable(cell: Any): Boolean = false
        override fun isCellEditable(cell: Any): Boolean = false
        override fun isCellResizable(cell: Any): Boolean = false

        fun cellForSystemDependencyResource(resource: SystemDependencyResource): mxCell? =
                getChildCells(getDefaultParent()).find { it.id == resource.id }

        fun cellForSystemDependencyResourceId(nodeId: String): mxCell? =
                getChildCells(getDefaultParent()).find { it.id == nodeId }

        fun doLayout() {
            val rootIds = if (currentUserRootId != null) listOf(currentUserRootId!!) else graphConfig.rootNodeIds
            val layoutRoots = rootIds.map { cellForSystemDependencyResourceId(it) }.filterNotNull().toTypedArray()

            if (layoutRoots.isEmpty()) {
                layout.execute(getDefaultParent())
            } else {
                layout.execute(getDefaultParent(), roots = layoutRoots)
            }
        }

        @Suppress("UNUSED_PARAMETER")
        private fun selectionHandler(sender: Any, event: Any) {
            val selectedCell = getSelectionCell()
            val res = selectedCell?.let { cellToResource(it) }
            this@SystemGraph.selectionModel.data = res ?: SystemDependencyResource.NULL
        }

        private fun vertices() = getChildCells(getDefaultParent(), true, false)
        private fun edges() = getChildCells(getDefaultParent(), false, true)

        private fun allNeighbourEdgeIds(cell: mxCell): Set<String> {
            val edges = getEdges(cell, null, true, true, true)
            return edges.map { it.id }.toSet()
        }

        private fun allNeighbourCellIds(cell: mxCell): Set<String> {
            val incomming = getIncomingEdges(cell).map { it.source }.map { it.id }
            val outgoing = getOutgoingEdges(cell).map { it.target }.map { it.id }
            return incomming.union(outgoing)
        }

        fun makeSelectionVisible() {
            val selectedResource = this@SystemGraph.selectionModel.data
            val selectedCell = cellForSystemDependencyResource(selectedResource)
            if (selectedCell != null) {
                // Palfred Have not found any examples of how to anymate view transformations (translate), this could meybe be done be coding out selves finding the needed steps and iteration with a window delay.
                scrollCellToVisible(selectedCell, true)
            }
        }

        fun applyStyleToAllElements() {
            highlight.hide()
            val selectedResource = this@SystemGraph.selectionModel.data
            val selectedCell = cellForSystemDependencyResource(selectedResource)
            val neighbourCellIds: Set<String> = selectedCell?.let { allNeighbourCellIds(it) } ?: setOf()
            val neighbourEdgeIds: Set<String> = selectedCell?.let { allNeighbourEdgeIds(it) } ?: setOf()
            val idsWithLabel = if (hightlightModel.data == Companion.SELECTION_HIGHLIGHT) {
                vertices().map { it.id }
            } else {
                this@SystemGraph.model.data.filter { it.x_infoflow.contains(hightlightModel.data) }.map { it.id }
            }
            for (edge in edges()) {
                var edgeStyle = if (edge.id in neighbourEdgeIds) "edge_highlight" else "edge"
                val lowlight = !(edge.target.id in idsWithLabel && edge.source.id in idsWithLabel)
                if (lowlight) {
                    edgeStyle += ";lowlight"
                }
                if (doubleArrorEdges.indexOf(edge) > -1) {
                    edgeStyle += ";doubleArrow"
                } else {
                    edgeStyle += ";singleArrow"
                }
                setCellStyle(edgeStyle, arrayOf(edge))
            }
            for (vertice in vertices()) {
                var cellStyle = nodeTypeToShape(cellToResource(vertice)?.data?.get("x_csaware_node_type"))
                cellStyle += if (vertice.id in neighbourCellIds) ";vertice_highlight_color" else ";vertice_normal_color"
                cellStyle += ";${riskStyle(vertice.id)}"
                if (vertice.id !in idsWithLabel) {
                    cellStyle += ";lowlight"
                }
                setCellStyle(cellStyle, arrayOf(vertice))
                updateCellSize(vertice, true)
            }
            if (selectedCell != null) {
                highlight.highlight(view.getState(selectedCell))
            }

            invisibleEdges.forEach { it.setVisible(false) }
        }

        init {
            getSelectionModel().addListener(mxEvent.CHANGE, this::selectionHandler)

            mxPanningManager(this)
            panningHandler.asDynamic()["popupMenuHandler"] = false

            apply {
                setConnectable(false)
                setPanning(true)
                centerZoom = false
                setAutoSizeCells(true);
                panningHandler.useLeftButtonForPanning = true;
            }

            layout = mxHierarchicalLayout(this, orientation = if (graphConfig.layoutDirection.isVertical()) mxConstants.DIRECTION_NORTH else mxConstants.DIRECTION_WEST).apply {
                interRankCellSpacing = graphConfig.spacing
            }

            if (UserInformation.hasAnyRole(UserRole.SystemAdministrator)) {
                popupMenuHandler.factoryMethod = { menu, cell, _ ->
                    val resource: SystemDependencyResource? = cell?.let { cellToResource(it) }
                    val sm = this@SystemGraph.selectionModel
                    val usedAsLayoutRoot = resource?.id == null || resource.id == currentUserRootId
                    val layoutRootMsg = if (usedAsLayoutRoot) {
                        CsawareMessages.get().system_depend_layout_default_root
                    } else {
                        CsawareMessages.get().system_depend_layout_user_root
                    }
                    menu.addItem(layoutRootMsg, null, { currentUserRootId = if (usedAsLayoutRoot) null else resource?.id }, iconCls = "fas fa-sitemap")
                    menu.addSeparator()

                    if (resource != null) {
                        for (func in UiFunctions.resourceFunctions) {
                            if (UserInformation.hasAnyRole(func.roles)) {
                                menu.addItem(func.label, null, { func.doIt(resource, sm) }, iconCls = func.iconCls)
                            }
                        }

                    } else {
                        for (func in UiFunctions.globalFunctions) {
                            if (UserInformation.hasAnyRole(func.roles)) {
                                menu.addItem(func.label, null, { func.doIt(SystemDependencyResource.NULL, sm) }, iconCls = func.iconCls)
                            }
                        }
                    }
                    menu.addSeparator()

                    if (hightlightModel.data == Companion.SELECTION_HIGHLIGHT) {
                        menu.addItem(CsawareMessages.get().system_depend_label_all, null, { hightlightModel.data = Companion.SELECTION_HIGHLIGHT }, iconCls = "fas fa-check")
                    } else {
                        menu.addItem(CsawareMessages.get().system_depend_label_all, null, { hightlightModel.data = Companion.SELECTION_HIGHLIGHT })
                    }
                    val tags = resource?.let { it.x_infoflow } ?: graphConfig.getValueSet("infoflow")
                    for (tag in tags.sorted()) {
                        if (hightlightModel.data == tag) {
                            menu.addItem(tag, null, { hightlightModel.data = tag }, iconCls = "fas fa-check")
                        } else {
                            menu.addItem(tag, null, { hightlightModel.data = tag })
                        }
                    }
                }
            }

            mxCellRenderer.registerShape("umlActor", ::UmlActorShape)
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CYLINDER
                resourceStyle[mxConstants.STYLE_SPACING] = 12
                resourceStyle[mxConstants.STYLE_SPACING_TOP] = 13
                resourceStyle[mxConstants.STYLE_ROUNDED] = true
                stylesheet.putCellStyle("cylinder_shape", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CLOUD
                resourceStyle[mxConstants.STYLE_SPACING] = 20
                resourceStyle[mxConstants.STYLE_ROUNDED] = true
                stylesheet.putCellStyle("cloud_shape", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_ELLIPSE
                resourceStyle[mxConstants.STYLE_SPACING] = 12
                resourceStyle[mxConstants.STYLE_ROUNDED] = true
                stylesheet.putCellStyle("ellipse_shape", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE
                resourceStyle[mxConstants.STYLE_SPACING] = 12
                resourceStyle[mxConstants.STYLE_ROUNDED] = true
                stylesheet.putCellStyle("rectangle_shape", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_TRIANGLE
                resourceStyle[mxConstants.STYLE_SPACING] = 12
                resourceStyle[mxConstants.STYLE_SPACING_RIGHT] = 20
                resourceStyle[mxConstants.STYLE_SPACING_LEFT] = 8
                resourceStyle[mxConstants.STYLE_ROUNDED] = true
                stylesheet.putCellStyle("triangle_shape", resourceStyle)
            }

            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_FILLCOLOR] = "#bbddff"
                resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#000000"
                resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 1
                stylesheet.putCellStyle("vertice_normal_color", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_FILLCOLOR] = "#bbddff"
                resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
                resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 5
                stylesheet.putCellStyle("vertice_highlight_color", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE
                resourceStyle[mxConstants.STYLE_ROUNDED] = true
                resourceStyle[mxConstants.STYLE_FILLCOLOR] = "#bbddff"
                resourceStyle[mxConstants.STYLE_SPACING] = 12
                resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#000000"
                resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 1
                stylesheet.putCellStyle("resource", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE
                resourceStyle[mxConstants.STYLE_ROUNDED] = true
                resourceStyle[mxConstants.STYLE_FILLCOLOR] = "#bbddff"
                resourceStyle[mxConstants.STYLE_SPACING] = 12
                resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 5
                resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
                stylesheet.putCellStyle("resource_highlight", resourceStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_OPACITY] = 35
                stylesheet.putCellStyle("lowlight", resourceStyle)
            }
            let {
                var edgeStyle = getStylesheet().getDefaultEdgeStyle().asDynamic();
                edgeStyle[mxConstants.STYLE_ROUNDED] = true;
                edgeStyle[mxConstants.STYLE_STROKECOLOR] = "#ffffff"
                edgeStyle[mxConstants.STYLE_STROKEWIDTH] = 1
                stylesheet.putCellStyle("edge", edgeStyle)
                getStylesheet().putDefaultEdgeStyle(edgeStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_ROUNDED] = true;
                resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
                resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 2
                stylesheet.putCellStyle("edge_highlight", resourceStyle)
            }
            let {
                var edgeStyle = js("{}")
                edgeStyle[mxConstants.STYLE_STARTARROW] = mxConstants.NONE
                edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC
                stylesheet.putCellStyle("singleArrow", edgeStyle)
            }
            let {
                var edgeStyle = js("{}")
                edgeStyle[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_CLASSIC
                edgeStyle[mxConstants.STYLE_STARTARROW] = mxConstants.ARROW_CLASSIC
                stylesheet.putCellStyle("doubleArrow", edgeStyle)
            }
            let {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_ROUNDED] = true;
                resourceStyle[mxConstants.STYLE_STROKECOLOR] = "#8DFF54"
                resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 2
                stylesheet.putCellStyle("edge_empty", resourceStyle)
            }
            val config: UserConfiguration = UserConfiguration.default
            for (severity in (1..5)) {
                var resourceStyle = js("{}")
                resourceStyle[mxConstants.STYLE_STROKECOLOR] = config.severityColor(severity).rgbHex
                resourceStyle[mxConstants.STYLE_STROKEWIDTH] = 1 + severity
                stylesheet.putCellStyle("severity-$severity", resourceStyle)
            }

            highlight = mxCellHighlight(this, "#8DFF54", 5, true)
            outline = mxOutline(this, outlineElement)
        }


    }

    class UmlActorShape : mxShape() {
        override fun paintBackground(c: Any, x: Any, y: Any, w: Any, h: Any) {
            val c1 = c as mxSvgCanvas2D
            val x1 = x as Int
            val y1 = y as Int
            val w1 = w as Int
            val h1 = h as Int
            c1.translate(x1, y1);

            // Head
            c1.ellipse(w1 / 4, 0, w1 / 2, h1 / 4);
            c1.fillAndStroke();

            c1.begin();
            c1.moveTo(w1 / 2, h1 / 4);
            c1.lineTo(w1 / 2, 2 * h1 / 3);

            // Arms
            c1.moveTo(w1 / 2, h1 / 3);
            c1.lineTo(0, h1 / 3);
            c1.moveTo(w / 2, h1 / 3);
            c1.lineTo(w1, h1 / 3);

            // Legs
            c1.moveTo(w1 / 2, 2 * h1 / 3);
            c1.lineTo(0, h);
            c1.moveTo(w1 / 2, 2 * h1 / 3);
            c1.lineTo(w1, h1);
            c1.end();

            c1.stroke();
        }
    }

    companion object {
        val SELECTION_HIGHLIGHT = "SELECTION_HIGHLIGHT_NOT_A_CATEGORY"
    }

}