package csaware.messages

import api.Cardinality
import dk.rheasoft.csaware.api.ShareState
import dk.rheasoft.csaware.api.ThreatState
import kafffe.core.FunctionalSubModel
import kafffe.core.MessagesStrategy
import kafffe.core.Model
import kafffe.core.ServiceRegistry
import kafffe.messages.Messages
import kafffe.messages.Messages.Companion.currentLanguage
import kafffe.messages.messagesModel
import kotlin.reflect.KProperty1

interface CsawareMessages : Messages {

    companion object {
        fun register() {
            ServiceRegistry.register("csaware_en", CsawareMessages_en())
            ServiceRegistry.register("csaware_da", CsawareMessages_da())
        }

        val allLanguages: List<CsawareMessages>
            get() = ServiceRegistry.findAll<CsawareMessages>()

        private var cache: CsawareMessages? = null
        private var cachedLanguage: String? = null

        fun get(): CsawareMessages {
            if (cachedLanguage != currentLanguage) {
                cache = get(currentLanguage)
                cachedLanguage = currentLanguage
            }
            return cache!!
        }

        fun get(language: CharSequence): CsawareMessages {
            return allLanguages.find { language.startsWith(it.language) } ?: CsawareMessages_en()
        }
    }

    val translation_automatic: String

    val nav_overview: String
    val nav_threats: String
    val nav_threatsClosed: String
    val nav_config: String
    val threat_group: String
    val threat_state: String
    val threat_state_initator: String
    val threat_state_history: String
    val threat_state_comment: String
    val threat_course_of_action: String
    val threat_subtype: String
    val threat_firstObserved: String
    val threat_endActive: String
    val threat_newState: String
    val threat_selfHealingConfirm: String
    val threat_where: String
    val threat_assignee: String

    val threat_stixtype: String
    val threat_stixtype_campaign: String
    val threat_stixtype_report: String
    val threat_stixtype_vulnerability: String
    val threat_stixtype_malware: String
    val threat_stixtype_attack_pattern: String
    val threat_stixtype_tool: String
    val threat_stixtype_unknown: String

    fun threatStixType(stixType: String) : String  =
            when (stixType) {
                "campaign" -> threat_stixtype_campaign
                "report" -> threat_stixtype_report
                "vulnerability" -> threat_stixtype_vulnerability
                "malware" -> threat_stixtype_malware
                "attack-pattern" -> threat_stixtype_attack_pattern
                "tool" -> threat_stixtype_tool
                else -> threat_stixtype_unknown
            }


    val threat_observed_data_objects: String
    val threat_observed_data_id: String
    val threat_observed_data_type: String
    val threat_observed_data_data: String

    val severity: String
    val count: String

    val threat_topTitle: String

    val threat_level_5_name: String
    val threat_level_5_text: String
    val threat_level_4_name: String
    val threat_level_4_text: String
    val threat_level_3_name: String
    val threat_level_3_text: String
    val threat_level_2_name: String
    val threat_level_2_text: String
    val threat_level_1_name: String
    val threat_level_1_text: String
    val threat_level_0_name: String
    val threat_level_0_text: String
    val threat_group_all: String
    val threat_nobody: String

    fun threatLevelName(level: Int): String {
        return when (level) {
            5 -> threat_level_5_name
            4 -> threat_level_4_name
            3 -> threat_level_3_name
            2 -> threat_level_2_name
            1 -> threat_level_1_name
            else -> threat_level_0_name
        }
    }

    fun threatLevelText(level: Int): String {
        return when (level) {
            5 -> threat_level_5_text
            4 -> threat_level_4_text
            3 -> threat_level_3_text
            2 -> threat_level_2_text
            1 -> threat_level_1_text
            else -> threat_level_0_text
        }
    }

    val ThreatState_Active: String
    val ThreatState_Resolved: String
    val ThreatState_Ignored: String
    val ThreatState_HealingAwaitDecision: String
    val ThreatState_HealingAccepted: String
    val ThreatState_HealingDeclined: String
    val ThreatState_Healed: String
    val ThreatState_HealingFailed: String
    val ThreatState_HealingInProgress: String
    val ThreatState_Unknown: String

    fun threatState(state: ThreatState) =
            when (state) {
                ThreatState.Active -> ThreatState_Active
                ThreatState.Resolved -> ThreatState_Resolved
                ThreatState.Ignored -> ThreatState_Ignored
                ThreatState.HealingAwaitDecision -> ThreatState_HealingAwaitDecision
                ThreatState.HealingAccepted -> ThreatState_HealingAccepted
                ThreatState.HealingDeclined -> ThreatState_HealingDeclined
                ThreatState.Healed -> ThreatState_Healed
                ThreatState.HealingFailed -> ThreatState_HealingFailed
                ThreatState.HealingInProgress -> ThreatState_HealingInProgress
                ThreatState.Unknown -> ThreatState_Unknown
            }

    val user_management: String
    val user_new: String
    val user_edit: String
    val user_subject: String
    val user_email: String
    val user_firstName: String
    val user_lastName: String
    val user_roles: String

    val informationshare_management: String
    val informationshare_short_description: String
    val informationshare_edit: String
    val informationshare_id: String
    val informationshare_description: String
    val informationshare_state: String
    val informationshare_create: String
    val informationshare_modified: String
    val informationshare_initiator: String
    val informationshare_history: String
    val informationshare_summary: String

    val ShareState_AwaitDecision: String
    val ShareState_Accept: String
    val ShareState_Decline: String
    val ShareState_Unknown: String

    fun shareState(state: ShareState) =
            when (state) {
                ShareState.PermissionNeeded -> ShareState_AwaitDecision
                ShareState.SharingAccepted -> ShareState_Accept
                ShareState.SharingDenied -> ShareState_Decline
                ShareState.unknown -> ShareState_Unknown
            }

    val system_dependencies: String

    val system_depend_id: String
    val system_depend_name: String
    val system_depend_description: String
    val system_depend_connectedFrom: String
    val system_depend_connectedTo: String
    val system_depend_infoflow: String
    val system_depend_category: String
    val system_depend_fields: String
    val system_depend_fields_valueset: String
    val system_depend_fields_valueset_values: String
    val system_depend_fields_to_shape_mapping: String
    val system_depend_layout: String
    val system_depend_layoutDirection: String
    val system_depend_layout_horizontal: String
    val system_depend_layout_vertical: String
    val system_depend_layout_roots: String
    val system_depend_layout_user_root: String
    val system_depend_layout_default_root: String
    val system_depend_spacing: String

    val system_depend_action_search: String
    val system_depend_action_new: String
    val system_depend_action_edit: String
    val system_depend_action_delete: String
    val system_depend_action_delete_confirm_title: String
    val system_depend_action_config: String
    val system_depend_action_import: String
    val system_depend_action_export: String
    val system_depend_action_print: String
    val system_depend_label_all: String

    open fun system_depend_field_cardinality(cardinality: Cardinality): String = when (cardinality) {
        Cardinality.One -> "1"
        Cardinality.ZeroOrOne -> "0..1"
        Cardinality.ZeroToMany -> "0..*"
        Cardinality.OneToMany -> "1..*"
    }

    val sign_out: String
    val sign_out_disclaimer: String
    val sign_out_link: String

    val mail_dialog_title: String
    val mail_to: String
    val mail_from: String
    val mail_subject: String
    val mail_body: String
    val mail_send: String

}

fun i18nText(prop: KProperty1<CsawareMessages, String>): Model<String> {
    val getMessage: (Model<Messages>) -> String = {
        val messages = CsawareMessages.get()
        prop.get(messages)
    }
    return FunctionalSubModel<String, Messages>(messagesModel, getData = getMessage)
}

fun csawareMessageStrategy(prefix: String? = null) = MessagesStrategy(prefix) { CsawareMessages.get() }



