package csaware.messages

import kafffe.messages.Messages_fr

open class CsawareMessages_fr : Messages_fr(), CsawareMessages {
    override val translation_automatic: String = "Traduction automatique"

    override val nav_overview = "Vue d'ensemble"
    override val nav_threats = "Des menaces"
    override val nav_threatsClosed = "Menaces fermées"
    override val nav_config = "Configuration"
    override val threat_group = "Groupe"
    override val threat_group_all = "Toutes"
    override val threat_nobody = "personne"
    override val threat_state = "Etat"
    override val threat_state_initator = "Qui"
    override val threat_state_history = "Histoire"
    override val threat_state_comment = "Commentaire"
    override val threat_course_of_action = "Plan d'action"
    override val threat_where = "Où"
    override val threat_assignee = "Cessionnaire"

    override val threat_stixtype = "Type"
    override val threat_stixtype_campaign = "Campagne"
    override val threat_stixtype_report = "Rapport"
    override val threat_stixtype_vulnerability = "Vulnérabilité"
    override val threat_stixtype_malware = "Malware"
    override val threat_stixtype_attack_pattern = "Modèle d'attaque"
    override val threat_stixtype_tool = "Outil"
    override val threat_stixtype_unknown = "Inconnu"

    override val threat_observed_data_objects = "Données observées"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Type"
    override val threat_observed_data_data = "Données"

    override val threat_subtype = "Sous-type"
    override val threat_firstObserved = "Premier observé"
    override val threat_endActive = "Fermé à"
    override val threat_newState = "Changer d'état"
    override val threat_selfHealingConfirm = "Confirmer l'auto-guérison"

    override val threat_topTitle = "Principales menaces"

    override val severity = "Gravité"
    override val count = "Compter"

    override val threat_level_5_name = "Critique"
    override val threat_level_5_text = "Une attaque est imminente."
    override val threat_level_4_name = "Sévère"
    override val threat_level_4_text = "Une attaque est très probable."
    override val threat_level_3_name = "substantiel"
    override val threat_level_3_text = "Forte possibilité d'attaque."
    override val threat_level_2_name = "Modérée"
    override val threat_level_2_text = "Une attaque est possible mais peu probable."
    override val threat_level_1_name = "Faible"
    override val threat_level_1_text = "Une attaque est peu probable."
    override val threat_level_0_name = "Inexistant"
    override val threat_level_0_text = "Aucune attaque prévue"

    override val ThreatState_Active = "Active"
    override val ThreatState_Resolved = "Résolu"
    override val ThreatState_Ignored = "Ignoré"
    override val ThreatState_HealingAwaitDecision = "L'auto-guérison a besoin de décision"
    override val ThreatState_HealingAccepted = "Auto-guérison accepter"
    override val ThreatState_HealingDeclined = "Auto-guérison refusée"
    override val ThreatState_Healed = "Auto-guérison réalisée"
    override val ThreatState_HealingFailed = "L'auto-guérison a échoué"
    override val ThreatState_HealingInProgress = "En cours d'auto-guérison"
    override val ThreatState_Unknown = "Inconnu"
    override val informationshare_summary = "Summary"

    override val user_management = "Gestion des utilisateurs"
    override val user_edit = "Modification de l'utilisateur"
    override val user_new = "Nouvel utilisateur"
    override val user_subject = "Sujet"
    override val user_email = "Email"
    override val user_firstName = "Prénom"
    override val user_lastName = "Nom de famille"
    override val user_roles = "Rôles"

    override val informationshare_management = "Partage d'information"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Modifier le partage d'informations"
    override val informationshare_id = "Id"
    override val informationshare_description = "Description"
    override val informationshare_state = "L' état"
    override val informationshare_create = "Créé"
    override val informationshare_modified = "Dernière modification"
    override val informationshare_initiator = "Qui"
    override val informationshare_history = "Histoire"

    override val ShareState_AwaitDecision = "Confirmer le partage"
    override val ShareState_Accept = "Acceptez"
    override val ShareState_Decline = "Refuser"
    override val ShareState_Unknown = "Inconnu"

    override val system_dependencies = "Système"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Nom"
    override val system_depend_description= "Description"
    override val system_depend_connectedFrom= "Connecté de"
    override val system_depend_connectedTo= "Se connecte à"
    override val system_depend_infoflow= "Flux d'information"
    override val system_depend_category= "Catégorie"
    override val system_depend_fields= "Champ"
    override val system_depend_fields_valueset = "Ensemble de valeurs de champ"
    override val system_depend_fields_valueset_values = "Valeurs"
    override val system_depend_fields_to_shape_mapping = "NodeType to Shape"
    override val system_depend_layout = "Plan"
    override val system_depend_layoutDirection = "Direction de mise en page"
    override val system_depend_layout_horizontal = "Horizontale"
    override val system_depend_layout_vertical = "Verticale"
    override val system_depend_layout_roots = "Racines"
    override val system_depend_layout_user_root = "Racines de la mise en page"
    override val system_depend_layout_default_root = "Mise en page par défaut"
    override val system_depend_spacing = "Espacement"

    override val system_depend_action_search = "Système de recherche"
    override val system_depend_action_new = "Créer un nouveau"
    override val system_depend_action_edit = "Modifier"
    override val system_depend_action_delete = "Supprimer"
    override val system_depend_action_delete_confirm_title = "Supprimer la ressource système"
    override val system_depend_action_config = "Configuration"
    override val system_depend_action_import = "Importation"
    override val system_depend_action_export = "Exportation"
    override val system_depend_action_print = "Impression"
    override val system_depend_label_all = "Tout"

    override val sign_out = "Déconnexion"
    override val sign_out_disclaimer = """
        |La session de visualisation CS-AWARE a été effacée,  
        |vous devrez vous déconnecter du fournisseur d'autorisation externe,  
        |si vous ne souhaitez pas vous reconnecter automatiquement. 
        |""".trimMargin()
    override val sign_out_link="Accédez à votre fournisseur d'autorisation pour vous déconnecter"

    override val mail_dialog_title = "Envoyer un mail"
    override val mail_to = "À"
    override val mail_from = "De"
    override val mail_subject = "Objet"
    override val mail_body = "Corps"
    override val mail_send = "Envoyé"

}

