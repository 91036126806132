package csaware.messages

import kafffe.messages.Messages_el

open class CsawareMessages_el : Messages_el(), CsawareMessages {
    override val translation_automatic: String = "Αυτόματη μετάφραση"

    override val nav_overview = "Επισκόπηση"
    override val nav_threats = "Απειλές"
    override val nav_threatsClosed = "Απειλές Κλειστές"
    override val nav_config = "Παραμετροποίηση"
    override val threat_group = "Ομάδα"
    override val threat_group_all = "Όλα"
    override val threat_nobody = "Κανένας"
    override val threat_state = "Κατάσταση"
    override val threat_state_initator = "Ποιός"
    override val threat_state_history = "Ιστορικό"
    override val threat_state_comment = "Comment"
    override val threat_course_of_action = "Πορεία Ενεργειών"
    override val threat_where = "Πού"
    override val threat_assignee = "Εντολοδόχος"

    override val threat_stixtype = "Τύπος"
    override val threat_stixtype_campaign = "Campaign"
    override val threat_stixtype_report = "Report"
    override val threat_stixtype_vulnerability = "Vulnerabilty"
    override val threat_stixtype_malware = "Malware"
    override val threat_stixtype_attack_pattern = "Attack Pattern"
    override val threat_stixtype_tool = "Tool"
    override val threat_stixtype_unknown = "Unknown"

    override val threat_observed_data_objects = "Observed Data"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Type"
    override val threat_observed_data_data = "Data"

    override val threat_subtype = "Υπό-Τύπος"
    override val threat_firstObserved = "Πρώτη εμφάνιση"
    override val threat_endActive = "Έκλεισε στις"
    override val threat_newState = "Αλλαγή Κατάστασης"
    override val threat_selfHealingConfirm = "Επιβεβαίωση Αυτο-Θεραπείας"

    override val threat_topTitle = "Κύριες Απειλές"

    override val severity = "Σοβαρότητα"
    override val count = "Πλήθος"

    override val threat_level_5_name = "Κρίσιμη"
    override val threat_level_5_text = "Αναμένεται επίθεση"
    override val threat_level_4_name = "Σοβαρή"
    override val threat_level_4_text = "Μια επίθεση είναι πολύ πιθανή"
    override val threat_level_3_name = "Πιθανή"
    override val threat_level_3_text = "Μια επίθεση είναι πολύ πιθανό να συμβεί"
    override val threat_level_2_name = "Μέτρια"
    override val threat_level_2_text = "Μια επίθεση είναι δυνατή, αλλά δεν είναι πιθανή."
    override val threat_level_1_name = "Χαμηλή"
    override val threat_level_1_text = "Μια επίθεση είναι απίθανη."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Ενεργή"
    override val ThreatState_Resolved = "Επιλύθηκε"
    override val ThreatState_Ignored = "Αγνοήθηκε"
    override val ThreatState_HealingAwaitDecision = "Η Αυτο-θεραπεία χρειάζεται απόφαση"
    override val ThreatState_HealingAccepted = "Αποδοχή Αυτο-θεραπείας"
    override val ThreatState_HealingDeclined = "Απόρριψη Αυτο-θεραπείας"
    override val ThreatState_Healed = "Ολοκλήρωση Αυτο-θεραπείας"
    override val ThreatState_HealingFailed = "Αποτυχία Αυτο-θεραπείας"
    override val ThreatState_HealingInProgress = "Αυτο-θεραπεία σε εξέλιξη"
    override val ThreatState_Unknown = "Άγνωστο"

    override val user_management = "Διαχείριση Χρήστη"
    override val user_edit = "Επεξεργασία Χρήστη"
    override val user_new = "Νέος Χρήστης"
    override val user_subject = "Αριθμός"
    override val user_email = "Email"
    override val user_firstName = "Όνομα"
    override val user_lastName = "Επώνυμο"
    override val user_roles = "Ρόλοι"

    override val informationshare_management = "Διαμοιρασμός Πληροφορίας"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Επεξεργασία Διαμοιρασμού Πληροφορίας"
    override val informationshare_id = "Id"
    override val informationshare_description = "Περιγραφή"
    override val informationshare_state = "Κατάσταση"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Τελευταία Τροποποίηση"
    override val informationshare_initiator = "Ποιός"
    override val informationshare_history = "Ιστορικό"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Επιβεβαίωση Διαμοιρασμού"
    override val ShareState_Accept = "Αποδοχή"
    override val ShareState_Decline = "Άρνηση"
    override val ShareState_Unknown = "Άγνωστο"

    override val system_dependencies = "Σύστημα"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Όνομα"
    override val system_depend_description= "Περιγραφή"
    override val system_depend_connectedFrom= "Σύνδεση Από"
    override val system_depend_connectedTo= "Σύνδεση Προς"
    override val system_depend_infoflow= "Ροή Πληροφορίας"
    override val system_depend_category= "Κατηγορία"
    override val system_depend_fields= "Πεδία"
    override val system_depend_fields_valueset = "Ομάδα Τιμών Πεδίων"
    override val system_depend_fields_valueset_values = "Τιμές"
    override val system_depend_fields_to_shape_mapping = "Τύπος Κόμβου προς Διαμοιρασμό"
    override val system_depend_layout = "Μορφή"
    override val system_depend_layoutDirection = "Κατεύθυνση"
    override val system_depend_layout_horizontal = "Οριζόντια"
    override val system_depend_layout_vertical = "Κάθετη"
    override val system_depend_layout_roots = "Προέλευση"
    override val system_depend_layout_user_root = "Layout root"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Απόσταση"

    override val system_depend_action_search = "Αναζήτηση Πόρου Συστήματος"
    override val system_depend_action_new = "Δημιουργία Νέου"
    override val system_depend_action_edit = "Επεξεργασία"
    override val system_depend_action_delete = "Διαγραφή"
    override val system_depend_action_delete_confirm_title = "Διαγραφή Πόρου Συστήματος"
    override val system_depend_action_config = "Παραμετροποίηση"
    override val system_depend_action_import = "Εισαγωγή"
    override val system_depend_action_export = "Εξαγωγή"
    override val system_depend_action_print = "Εκτύπωση"
    override val system_depend_label_all = "Όλα"

    override val sign_out = "Αποσύνδεση"
    override val sign_out_disclaimer = """
        |Η συνεδρία του CS-AWARE Visualisation έχει εκαθαριστεί,  
        |θα πρέπει να αποσυνδεθείτε από τον εξωτερικό Πάροχο Εξουσιοδότησης,  
        |εάν δεν επιθυμείτε να συνδεθείτε ξανά αυτόματα. 
        |""".trimMargin()
    override val sign_out_link="Μεταβείτε στον Πάροχο Εξουσιοδότησης προκειμένου να αποσυνδεθείτε."

    override val mail_dialog_title = "Αποστολή Email"
    override val mail_to = "Παραλήπτης"
    override val mail_from = "Αποστολέας"
    override val mail_subject = "Θέμα"
    override val mail_body = "Σώμα Κειμένου"
    override val mail_send = "Αποστολή"

}

