package csaware.systemdepend.config

import csaware.messages.CsawareMessages
import csaware.messages.csawareMessageStrategy
import csaware.messages.i18nText
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.FormGroupTextArea
import kafffe.bootstrap.form.textArea
import kafffe.core.*
import org.w3c.dom.HTMLElement
import org.w3c.files.FileList
import org.w3c.files.FileReader
import org.w3c.files.get

class SystemResourceImportDlg() :
        FormDialog<String>(i18nText(CsawareMessages::system_depend_action_import), Model.of("")) {

    val fileData: Model<String> = Model.of("")
    var textField: FormGroupTextArea? = null

    inner class FileInput : KafffeComponent() {
        val input = htmlStart.input {
            addClass("form-control-file")
            withElement {
                type = "file"
                onchange = {
                    val files: FileList = it.target.asDynamic().files; // FileList object
                    if (files.length == 1) {
                        val reader = FileReader()
                        reader.onload = {
                            fileData.data = reader.result as String
                            textField?.refreshFromValueModel()
                        }

                        // Read in the image file as a data URL.
                        reader.readAsText(files[0]!!)
                        it.preventDefault()
                    }
                }
            }
        }.element!!

        override fun createHtml(): HTMLElement {
            return input
        }
    }

    val fileInput = FileInput()

    init {
        labelStrategy = csawareMessageStrategy("system_depend_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1400px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-info text-light"))

        addChild(fileInput)

        textField = textArea("data", Model.of("JSON"), fileData).apply { lines = 24 }
        submit().apply {
            color = BasicColor.primary
            addClass("mr-2") // add space to the right after submit
        }
        cancel().color = BasicColor.secondary
    }

}

