package csaware.user

import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.UserRole
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessageStrategy
import csaware.messages.i18nText
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.checkbox
import kafffe.core.*

class UserEditDlg(user: User, allowDelete: Boolean, val refresh: () ->Unit) : FormDialog<User>(i18nText(CsawareMessages::user_edit), Model.of(user)) {

    init {
        addClass("hgap-3")
        labelStrategy = csawareMessageStrategy("user_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1400px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-info"))

        readonly(User::subject)
        input(User::email)
        readonly(User::firstName)
        readonly(User::lastName)
        group {
            label(User::roles.name)
            for (role in UserRole.values()) {
                checkbox(role.name, model.func({ um -> um.data[role] }, { um: Model<User>, v: Boolean -> um.data[role] = v }), Model.of(role.name))
            }
        }
        submit("save", onOk = {
            CsawareServices.alerts.clearAlerts()
            CsawareServices.backend.updateUser(model.data) {
                refresh()
            }
            detach()
        }).color = BasicColor.primary
        if (allowDelete) {
            submit("delete", onOk = {
                CsawareServices.alerts.clearAlerts()
                CsawareServices.backend.deleteUser(model.data) {
                    refresh()
                }
                detach()
            }).color = BasicColor.danger
        }
        cancel().color = BasicColor.secondary
    }

}