package csaware.overview

import kafffe.bootstrap.BootstrapButton
import kafffe.core.CssClassModifier
import kafffe.core.DivContainer
import kafffe.core.Model

class PlayButtons(val onAction: (action: PlayAction) -> Unit) : DivContainer() {
    enum class PlayAction {
        play, stop, step_forward, step_backward, fast_forward, fast_backward
    }

    private fun addButton(iconClassed: String, action: PlayAction): BootstrapButton {
        val btn = BootstrapButton(Model.of("")) { onAction(action) }
        btn.iconClasses = iconClassed
        addChild(btn)
        return btn
    }

    val fastBackwardBtn = addButton("fas fa-fast-backward", PlayAction.fast_backward)
    val stepBackwardBtn = addButton("fas fa-step-backward", PlayAction.step_backward)
    val stopBtn = addButton("fas fa-pause", PlayAction.stop)
    val playBtn = addButton("fas fa-play", PlayAction.play)
    val stepForwardBtn = addButton("fas fa-step-forward", PlayAction.step_forward)
    val fastForwardBtn = addButton("fas fa-fast-forward", PlayAction.fast_forward)

    var playing: Boolean = false
        set(value) {
            field = value
            fastBackwardBtn.disabled = value
            stepBackwardBtn.disabled = value
            stopBtn.disabled = !value
            playBtn.disabled = value
            stepForwardBtn.disabled = value
            fastForwardBtn.disabled = value
        }

    init {
        modifiers.add(CssClassModifier("hgap-3 text-center"))
        playing = false
    }
}
