package csaware.overview

import dk.rheasoft.csaware.api.ThreatOverview
import dk.rheasoft.csaware.api.timestampNow
import csaware.get
import csaware.level
import csaware.levelRatio
import csaware.messages.CsawareMessages
import io.data2viz.color.Color
import io.data2viz.color.colors
import io.data2viz.shape.ArcGenerator
import io.data2viz.viz.ParentElement
import io.data2viz.viz.TextAlignmentBaseline
import io.data2viz.viz.TextAnchor
import io.data2viz.viz.viz
import kafffe.core.KafffeComponent
import kafffe.core.Model
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.min
import kotlin.math.sin

open class SvgGraph() : KafffeComponent() {

    val svg: HTMLElement = htmlStart.svg().element!!


    override fun createHtml(): HTMLElement {
        root()?.apply {
            fun d2vJs(name: String) {
                addJsScriptRef(name, "${name}-js.js")
            }
            // would like to somehow automatically import these
            /*
            d2vJs("d2v-core")
            d2vJs("d2v-color")
            d2vJs("d2v-time")
            d2vJs("d2v-timeFormat")
            d2vJs("d2v-interpolate")
            d2vJs("d2v-scale")
            d2vJs("d2v-path")
            d2vJs("d2v-viz")
            d2vJs("d2v-axis")
            */
        }
        return svg
    }
}


class DartGraph(
        val config: UserConfiguration = UserConfiguration.default,
        var clickHandler: (event: Event, threatType: String?) -> Unit = { _, _ -> })
    : SvgGraph() {

    var threats = ThreatOverview(timestampNow(), listOf())

    val threatGroupsModel = Model.of(listOf<String>())

    init {
        redraw()
    }

    fun redraw() {
        val width = config.dartWidth;
        val height = config.dartHeight;
        val maxRadius: Double = min(width, height) / 2.0;

        val pieStrokeWidth: Double = config.dartStrokeWidth;

        val threatTypesAll = mutableSetOf<String>()
        threatTypesAll.addAll(threats.threatGroups.map { it.group })
        threatTypesAll.addAll(threatGroupsModel.data)
        val threatTypes = threatTypesAll.toList().sorted()

        val numberOfSlices = threatTypes.size;
        val sliceSize: Double = PI * 2 / numberOfSlices;
        val pieRadius: Double = maxRadius - 2.0 * pieStrokeWidth;
        val centerRadius: Double = config.dartCenterCircleSize;

        svg.setAttribute("height", config.dartHeight.toString())
        svg.setAttribute("width", config.dartWidth.toString())

        svg.querySelector("g")?.remove()


        svg.viz {
            transform {
                translate(x = config.dartWidth / 2.0, y = config.dartHeight / 2.0)
            }

            // Background Circle
            circle {
                cx = 0.0
                cy = 0.0
                radius = pieRadius
                fill = colors.wheat
                stroke = colors.black
                strokeWidth = pieStrokeWidth
            }

            // Center Circle
            val center =
                    group {
                        val level = threats.level()
                        circle {
                            cx = 0.0
                            cy = 0.0
                            radius = centerRadius - 4.0
                            fill = config.severityColor(level)
                        }
                        if (level == 0) {
                            // Smiley
                            val smile = ArcGenerator<Double>().apply {
                                innerRadius = { centerRadius / 2 }
                                outerRadius = { centerRadius / 2 + 8 }
                                startAngle = { it }
                                endAngle = { it + PI }
                            }
                            path {
                                smile.arc(PI / 2.0, this)
                                fill = colors.black
                                stroke = colors.black
                                strokeWidth = 1.0
                            }
                            // eyes
                            circle {
                                cx = cos(- PI /4.0 - PI / 2.0) * centerRadius / 2.0
                                cy = sin(- PI /4.0 - PI / 2.0) * centerRadius / 2.0
                                radius = 8.0
                                fill = colors.black
                                stroke = colors.black
                                strokeWidth = 1.0
                            }
                            circle {
                                cx = cos(- PI /4.0) * centerRadius / 2.0
                                cy = sin(- PI /4.0) * centerRadius / 2.0
                                radius = 8.0
                                fill = colors.black
                                stroke = colors.black
                                strokeWidth = 1.0
                            }
                        } else {
                               text {
                                transform {
                                    translate(0.0, 0.0)
                                }
                                fill = config.severityTextColorMap[level]
                                textContent = CsawareMessages.get().threatLevelName(level)
                                anchor = TextAnchor.MIDDLE
                                baseline = TextAlignmentBaseline.MIDDLE
                            }
                        }
                    }
            (center as ParentElement).domElement.addEventListener("click", { e -> clickHandler(e, null) })

            var startRad = 0.0;
            var colorIx = 0
            val colorArray = arrayOf(
                    Color(0x3e9f8b),
                    Color(0x0076ff),
                    Color(0x66b7aa),
                    Color(0x7de5fb),
                    Color(0x8ccec7),
                    Color(0xb3e7e4),
                    Color(0x00c5ff),
                    Color(0x00a1ff),
                    Color(0x7180A2),
                    Color(0xCCA75F),
                    Color(0xCC905F),
                    Color(0xA07929),
                    Color(0xD7D079),
                    Color(0xFFEFBD),
                    Color(0xAFA63F)
            )

            val maxSeveritySum = threats.threatGroups.map { it.countSum * it.severityAvg }.max() ?: 1.0
            for (threatType in threatTypes) {
                val threat = threats[threatType]
                val slice =
                        group {
                            // Draw threattype arc
                            // Need to calc outer radius based on severity and count ?
                            val severity = threat.levelRatio(maxSeveritySum)
                            val ir = centerRadius
                            val or = (pieRadius - centerRadius) * severity + centerRadius
                            val frameGen = ArcGenerator<Double>().apply {
                                innerRadius = { ir }
                                outerRadius = { pieRadius }
                                startAngle = { it }
                                endAngle = { it + sliceSize }
                            }
                            path {
                                frameGen.arc(startRad, this)
                                fill = colors.wheat
                                stroke = colors.black
                                strokeWidth = pieStrokeWidth
                            }

                            val arcColorGen = ArcGenerator<Double>().apply {
                                innerRadius = { ir }
                                outerRadius = { or }
                                startAngle = { it }
                                endAngle = { it + sliceSize }
                            }

                            path {
                                arcColorGen.arc(startRad, this)
                                //  fill = colorArray[colorIx]
                                //user severity color
                                fill =  config.severityColor(threat.severityMax)
                                stroke = colors.black
                                strokeWidth = 1.0
                            }
                            val arcSeverityGen = ArcGenerator<Double>().apply {
                                innerRadius = { pieRadius - config.dartSeverityWidth }
                                outerRadius = { pieRadius }
                                startAngle = { it }
                                endAngle = { it + sliceSize }
                            }
                            path {
                                arcSeverityGen.arc(startRad, this)
                                fill = config.severityColor(threat.severityMax)
                                stroke = colors.black
                                strokeWidth = 1.0
                            }

                            val textRad = (startRad + sliceSize / 2) - (PI / 2) // the angle of the arcs start at twelwe
                            val textX: Double = 0.7 * pieRadius * cos(textRad)
                            val textY: Double = 0.7 * pieRadius * sin(textRad)
                            text {
                                transform {
                                    translate(textX, textY)
                                }
                                fill = colors.black
                                textContent = threatType
                                anchor = TextAnchor.MIDDLE
                                baseline = TextAlignmentBaseline.MIDDLE
                            }
                        }
                (slice as ParentElement).domElement.addEventListener("click", { e -> clickHandler(e, threatType) })

                startRad += sliceSize
                colorIx++
                if (colorIx >= colorArray.size) {
                    if (threatTypes.size % colorArray.size < 2) {
                        colorIx = 2
                    } else {
                        colorIx = 0
                    }
                }
            }

        }
    }

}


