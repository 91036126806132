package csaware

import api.SystemDependencyConfig
import csaware.overview.UserConfiguration
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.kafffenv

class About : KafffeComponent() {
    data class UsedSw(val name: String, val link: String)

    val useSw = listOf<UsedSw>(
            UsedSw("Kotlin", "https://kotlinlang.org/"),
            UsedSw("Kotson", "https://github.com/SalomonBrys/Kotson"),
            UsedSw("Fuel", "https://fuel.gitbook.io"),
            UsedSw("Sparkjava", "http://sparkjava.com/"),
            UsedSw("Gson","https://github.com/google/gson"),
            UsedSw("JSON Web Token","https://www.jsonwebtoken.io/"),
            UsedSw("Data2viz", "https://data2viz.io/"),
            UsedSw("Bootstrap", "https://getbootstrap.com/docs/4.0/about/overview/"),
            UsedSw("Flag icons", "https://www.countryflags.com/"),
            UsedSw("Fontawesome", "https://fontawesome.com"),
            UsedSw("Apache POI", "https://poi.apache.org/")
    )

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
            addClass("card mt-2")
            h3 {
                addClass("card-header csaware-field text-light text-center")
                + "About CS-Aware Visualisation"
            }
            div {
                addClass("card-body row")
                div {
                    addClass("col-lg-7 col-md-12")
                    p {
                       + "CS-AWARE has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 740723. "
                       + "CS-AWARE us a holistic cybersecurity awareness solution aimed atlocal public administrations, non-governmental organizations and small "
                       + "and medium enterprises to protect against and detect cyber attacks."
                    }
                    p {  + "The European Commission is not responsible for any use that may be made of the information it contains." }
                    p {
                        a("https://cs-aware.eu") {
                            element?.target = "_blank"
                            + "Read more"
                        }
                    }
                    p {
                        a("https://cs-aware.eu/project-partners/") {
                            element?.target = "_blank"
                            + "Partners"
                        }
                    }
                    h2 {
                        text("Used software")
                    }
                    ul {
                        useSw.forEach {
                            li {
                                a(it.link) {
                                    element?.target = "_blank"
                                    text(it.name)
                                }
                            }
                        }
                    }

                }
                div {
                    addClass("col-lg-5 col-md-12")
                    div {
                        addClass("csaware-field")
                        h2 {
                            text("Environment")
                        }
                        p {
                            h5 {
                                element?.style?.apply {
                                    width = "14rem"
                                    display = "inline-block"
                                }
                                text("Name:")
                            }
                            text(kafffenv.name)
                        }
                        p {
                            h5 {
                                element?.style?.apply {
                                    width = "14rem"
                                    display = "inline-block"
                                    verticalAlign = "top"
                                }
                                text("Version:")
                            }
                            span {
                                element?.style?.apply {
                                    whiteSpace = "pre"
                                    display = "inline-block"
                                    verticalAlign = "top"
                                }
                                text(kafffenv.version)
                            }
                        }
                        p {
                            h5 {
                                element?.style?.apply {
                                    width = "14rem"
                                    display = "inline-block"
                                }
                                text("Build time:")
                            }
                            text(kafffenv.buildtime)
                        }

                    }
                    img { element?.src = "images/csaware-logobig.png" }
                }
            }
        }

}