package csaware.systemdepend

import api.FieldType
import api.SystemDependencyField
import api.SystemDependencyResource
import csaware.main.CsawareServices
import csaware.main.CsawareServices.alerts
import csaware.main.CsawareServices.backend
import csaware.main.UserInformation
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.overview.UserConfiguration
import csaware.systemdepend.UiFunctions.Companion.globalFunctions
import csaware.systemdepend.UiFunctions.Companion.resourceFunctions
import csaware.threats.Popovers
import csaware.threats.ThreatStateChangeDlg
import csaware.threats.ThreatStateSymbol
import csaware.threats.WhereLink
import csaware.translation.TranslationModel
import dk.rheasoft.csaware.api.StateHistory
import dk.rheasoft.csaware.api.ThreatObservation
import dk.rheasoft.csaware.api.UserRole
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.core.*
import kafffe.messages.Messages.Companion.formatDateTime
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableElement
import kotlin.dom.addClass


class SystemDetails(selectionModel: Model<SystemDependencyResource>, val hightlightModel: Model<String>) : KafffeComponentWithModel<SystemDependencyResource>(selectionModel) {

    val descriptionRender = addChild(MarkdownRender(model.property(SystemDependencyResource::description)))
    val titleLabel = addChild(Label(model.property(SystemDependencyResource::name)))

    val threatListModel = Model.of(listOf<ThreatObservation>())
    val threatTable = addChild(BootstrapTable<ThreatObservation>(threatListModel).apply {
        rowClickHandler = {ThreatStateChangeDlg.show(it)}

        addStyle(BootstrapTableStyles.striped)
        // addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))
        modifiers.add(CssClassModifier("csaware-field"))
        modifiers.add(StyleModifier { display = if (model.data.isEmpty()) "none" else "block" })

        colEx(i18nText(CsawareMessages::threat_state), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.bgColor = UserConfiguration.default.severityColorMap[rowData.severity].rgbHex
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })
        col(i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.formatDateTime()) })
        col(i18nText(CsawareMessages::threat_group), { Label(it.threatGroup) })
        col(i18nText(CsawareMessages::threat_where), { WhereLink(it.whereSightedRefs)}).apply { rowClick = false }

        colEx(i18nText(CsawareMessages::name), { observation, cell ->
            val translationModel = TranslationModel(Model.of(observation.descriptionSoMe))
            Popovers.description({ translationModel.translation }).modify(cell)
            Label(observation.name)
        })

    })

    override fun attach() {
        super.attach()
        hightlightModel.listeners.add(onModelChanged)
    }

    override fun detach() {
        super.detach()
        hightlightModel.listeners.remove(onModelChanged)
    }

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                addClass("card text-light")
                htmlHeader()
                htmlBody()
            }

    private fun KafffeHtml<HTMLDivElement>.htmlBody() {
        div {
            withElement {
                addClass("card-body", "bg-secondary")
                style.apply {
                    width = "100%"
                    height = "85vh"
                    padding = "1em"
                    overflowY = "auto"
                }

                add(threatTable.html)

                htmlDescription()

                htmlInfoFlow()

                if (model.data.data.isNotEmpty()) {
                    table {
                        withElement {
                            style.apply {
                                borderCollapse = "separate"
                                borderSpacing = "1em 0.3em"
                                marginLeft = "-1em"
                                marginTop = "0.2em"
                            }
                        }
                        //val definedFieldsIds = CsawareServices.systemDependencies.config.data.fields.map { it.id }.toList()
                        for (field in CsawareServices.systemDependencies.config.data.fields) {
                            // Single value 0..1 or 1 - actual if 0 it should not be there
                            htmlFieldRow(field)
                        }
                    }
                }

            }

        }
    }

    private fun KafffeHtml<HTMLTableElement>.htmlFieldRow(field: SystemDependencyField) {
        val hasSingleValue = model.data.data.containsKey(field.id) && field.cardinality.isSingle
        // Multi value 0..* or 1..* - actual if 0 it should not be there
        val hasMultiValue = model.data.dataLists.containsKey(field.id) && field.cardinality.isMany

        if (hasSingleValue || hasMultiValue) {
            tr {
                td {
                    h5 {
                        addClass("csaware-label-infront")
                        text(field.label)
                    }
                }
                td {
                    addClass("csaware-field pl-2")
                    element?.style?.minWidth = "16em"
                    if (hasSingleValue) {
                        val  value = model.data.data[field.id]!!
                        when (field.type) {
                            FieldType.MARKDOWN -> {
                                add(MarkdownRender(value).html)
                            }
                            FieldType.URL -> a {
                                withElement {
                                    href = value
                                    target = "_"
                                }
                                text(value)
                            }
                            else -> text(if (field.type == FieldType.SECRET) "******" else value)
                        }
                    } else {
                        text(" ")
                        for (value in model.data.dataLists[field.id]!!) {
                            span {
                                addClass("badge badge-multi-value")
                                when (field.type) {
                                    FieldType.MARKDOWN -> {
                                        add(MarkdownRender(value).html)
                                    }
                                    FieldType.URL -> a {
                                        withElement {
                                            href = value
                                            target = "_"
                                        }
                                        text(value)
                                    }
                                    else ->
                                        text(if (field.type == FieldType.SECRET) "******" else value)
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    private fun KafffeHtml<HTMLDivElement>.htmlInfoFlow() {
        if (model.data.x_infoflow.isNotEmpty()) {
            h4 {
                addClass("csaware-label-above")
                text(CsawareMessages.get().system_depend_infoflow)
            }
            div {
                addClass("csaware-field")
                text(" ")
                for (tag in model.data.x_infoflow) {
                    span {
                        addClass("badge badge-multi-value")
                        if (tag == hightlightModel.data) {
                            addClass("csaware-highlight-border")
                            i { addClass("fas fa-check csaware-highlight-color") }
                        }
                        element?.onclick = { hightlightModel.data = if (tag == hightlightModel.data) SystemGraph.SELECTION_HIGHLIGHT else tag; it.preventDefault() }
                        text(tag)
                    }
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.htmlDescription() {
        if (model.data.description.isNotBlank()) {
            div {
                addClass("csaware-field")
                add(descriptionRender.html)
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.htmlHeader() {
        div {
            addClass("h4")
            addClass("card-header csaware-field text-light")
            add(titleLabel.html.also { it.addClass("mr-auto") })
            if (UserInformation.hasAnyRole(UserRole.SystemAdministrator)) {
                div {
                    addClass("btn-group float-right")
                    for (func in resourceFunctions) {
                        if (UserInformation.hasAnyRole(func.roles)) {
                            button {
                                addClass("btn")
                                element?.title = func.label
                                i { addClass(func.iconCls) }
                                onClick { func.doIt(model.data, model) }
                            }
                        }
                    }
                    div {
                        addClass("btn-group")
                        element?.setAttribute("role", "group")
                        button {
                            addClass("btn dropdown-toggle")
                            withElement {
                                id = "dropdownConfigMenuButton"
                                setAttribute("data-toggle", "dropdown")
                                setAttribute("aria-haspopup", "true")
                                setAttribute("aria-expanded", "false")
                                style.textAlign = "left"
                            }
                            i { addClass("fas fa-cog") }

                        }

                        div {
                            addClass("dropdown-menu dropdown-menu-right bg-info text-white")
                            element?.setAttribute("aria-labelledby", "dropdownConfigMenuButton")
                            for (func in globalFunctions) {
                                if (UserInformation.hasAnyRole(func.roles)) {
                                    a {
                                        addClass("dropdown-item bg-info text-white")
                                        i { addClass("${func.iconCls} fa-fw mr-2") }
                                        text(func.label)
                                        onClick { func.doIt(model.data, model) }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}