package csaware.overview

import io.data2viz.color.Color
import io.data2viz.color.colors

data class UserConfiguration(
        var dartWidth: Int = 500,
        var dartHeight: Int = 500,
        var dartStrokeWidth: Double = 1.0,
        var dartCenterCircleSize: Double = 90.0,
        var dartSeverityWidth: Double = 12.0,

        var severityColorMap: Array<Color> = arrayOf<Color>(colors.green, colors.limegreen, colors.royalblue, colors.yellow, colors.darkorange, colors.red),
        var severityTextColorMap: Array<Color> = arrayOf<Color>(colors.black, colors.black, colors.white, colors.black, colors.white, colors.yellow),

        var topNumberOfThreats: Int = 10,
        var nofHistoryDays: Int = 21,
        var playDelayMs: Int = 600
) {

    fun severityColor(severity: Int) = severityColorMap[severity % severityColorMap.size]
    fun severityTextColor(severity: Int) = severityTextColorMap[severity % severityTextColorMap.size]

    companion object {
        val default = UserConfiguration();
    }

}