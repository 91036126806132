package csaware.main

import csaware.comm.CSAwareBackend
import csaware.messages.*
import csaware.systemdepend.SystemDependencyService
import kafffe.bootstrap.Alert
import kafffe.bootstrap.AlertsPanel
import kafffe.bootstrap.BootstrapLevel
import kafffe.core.ServiceRegistry

object CsawareServices {
    // alerts registered by Csaware main it is a UI component.
    val alerts by lazy { ServiceRegistry.findByType(AlertsPanel::class)!! }
    val systemDependencies by lazy { ServiceRegistry.findByType(SystemDependencyService::class)!! }

    val backend = CSAwareBackend(::exceptionHandler)

    private fun exceptionHandler(@Suppress("UNUSED_PARAMETER") status: Int, message: String) {
        alerts.addAlert(Alert(message, BootstrapLevel.error))
    }

    fun initServices() {
        ServiceRegistry.register("csaware_en", CsawareMessages_en())
        ServiceRegistry.register("csaware_da", CsawareMessages_da())
        ServiceRegistry.register("csaware_it", CsawareMessages_it())
        ServiceRegistry.register("csaware_gr", CsawareMessages_el())
        ServiceRegistry.register("csaware_fr", CsawareMessages_fr())
        ServiceRegistry.register("csaware_de", CsawareMessages_de())

        ServiceRegistry.register("systemDependencies", SystemDependencyService())
        systemDependencies.refresh()
        systemDependencies.refreshConfig()
    }


}