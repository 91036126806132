package csaware.test

import csaware.comm.CSAwareBackend
import csaware.messages.CsawareMessages
import csaware.overview.UserConfiguration
import csaware.threats.ThreatStateSymbol
import dk.rheasoft.csaware.api.*
import kafffe.bootstrap.*
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.FormGroupDropdownString
import kafffe.bootstrap.form.dropdown
import kafffe.bootstrap.form.textArea
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.messages.Messages.Companion.formatDateTime
import org.w3c.dom.HTMLTableCellElement
import kotlin.js.Json
import kotlin.math.ceil

class SelfHealingTestPage : DivContainer() {
    val nowStr = timestampNow().toJsonFormat()
    val selfHealingData = ""
    var courseOfActionName = ""
    var courseOfAction = """{
                "id": "course-of-action--8e2e2d2b-17d4-4cbf-938f-98ee46b3cd3f",
                "name": "jqueryui_mitigation",
                "description": "CS-AWARE SIMULATION: Download and apply the update packages found in the following link: https://access.redhat.com/errata/RHSA-2015:1462"
            }"""

    fun template(threatId: String, state: String): String {
        val details = if (state == "confirm") """
            "course_of_action": $courseOfAction
        """ else """
                "description": "Some change info"
        """
        return """{
      "id": "$threatId",
      "state": "$state",
      $details
    }"""
    }

    val alerts = addChild(AlertsPanel())

    data class FormData(var healingdata: String)

    val input = Model.of(FormData(template("TODO", "confirm")))
    val bundleNames: Model<List<String>> = Model.of(listOf(""))

    val form = addChild(BootstrapForm<FormData>(input).apply {
        val coaSelector: FormGroupDropdownString = dropdown("select", Model.of("Course of action"), PropertyModel<String>(this@SelfHealingTestPage::courseOfActionName), bundleNames)
        coaSelector.onchange {
            coaSelector.updateValueModel()
            loadCourseOfActions()
        }
        textArea(FormData::healingdata).apply {
            modifiersInput.add(StyleModifier() {
                height = "20vh"
                width = "80vw"
            })
            required = true
        }
        submit()
        onSubmitOk = this@SelfHealingTestPage::submitOk
    })

    val spacer = addChild(Label(Model.of("-")))

    val table = addChild(BootstrapTable.create<ThreatObservation>(listOf()) {
        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))
        col(Model.of("Pick"), { observation ->
            DivContainer().apply {
                modifiers.add(CssClassModifier("btn-group"))
                fun btn(title: String, state: String) = addChild(BootstrapButton(Model.of(title)) { input.data = FormData(template(observation.id, state)) })
                if (observation.state in setOf(ThreatState.Active, ThreatState.HealingDeclined, ThreatState.Unknown))
                    btn("Confirm?", "confirm").color = BasicColor.primary
                if (observation.state in setOf(ThreatState.HealingAccepted))
                    btn("Progress", "progress")
                if (observation.state in setOf(ThreatState.HealingAccepted, ThreatState.HealingInProgress)) {
                    btn("Healed", "healed").color = BasicColor.success
                    btn("Failed", "failed").color = BasicColor.error
                }
            }
        })
        colEx(csaware.messages.i18nText(CsawareMessages::severity), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.bgColor = UserConfiguration.default.severityColorMap[rowData.severity].rgbHex
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })
        col(csaware.messages.i18nText(CsawareMessages::threat_endActive), { Label(it.endActive.formatDateTime()) })
        col(csaware.messages.i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.formatDateTime()) })
        col(csaware.messages.i18nText(CsawareMessages::threat_group), { Label(it.threatGroup) })
        col(csaware.messages.i18nText(CsawareMessages::name), { Label(it.name) })

    })

    var pageSize: Int = 6
    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager))

    init {
        pager.changeListeners.add({ loadData() })
    }

    init {
        loadData()
    }

    protected fun loadCourseOfActions() {
        backend().getTxt("/test/usecase/selfhealing/" + courseOfActionName) { data ->
            courseOfAction = data
        }
    }

    fun loadData() {
        alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        backend().getThreatsCurrentActive(timestampNow(), offset, pageSize, this::receiveData)
        backend().getTxt("/test/usecase/selfhealing/list") {
            val json: Array<String> = JSON.parse(it)
            bundleNames.data = json.toList()
        }
    }

    fun receiveData(response: QueryResult<ThreatObservation>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    fun submitOk() {
        alerts.clearAlerts()
        println(input.data.healingdata)
        // treePrint(parse(input.data.healingdata))
        backend().sendJsonTxt("/test/selfhealing/update", "POST", input.data.healingdata) {
            alerts.addAlert(Alert("Self Healing test send: $it", BootstrapLevel.info))
        }
    }

    private fun treePrint(d: Json, level: Int = 0) {
        val keys: Array<String> = js("Object").keys(d)
        for (a in keys) {
            for (i in 1..level) print("  ")
            if (jsTypeOf(d[a]) == "object") {
                println(a)
                treePrint(d[a].unsafeCast<Json>(), level + 1)
            } else {
                println("$a = ${d[a]}")
            }
        }
    }

    protected fun errorHandler(code: Int, text: String) {
        alerts.addAlert(Alert("($code) ${text}", BootstrapLevel.danger))
    }

    protected fun backend() = CSAwareBackend(this::errorHandler)
}