package csaware.main

import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class AutoTranslationMenuItem(model : Model<Boolean>) : KafffeComponentWithModel<Boolean>(model) {
    val labelModel = i18nText(CsawareMessages::translation_automatic)

    override fun attach() {
        super.attach()
        labelModel.listeners.add(onModelChanged)
    }

    override fun detach() {
        labelModel.listeners.remove(onModelChanged)
        super.detach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        a {
            addClass("dropdown-item")
            val isOn = model.data
            val icon = if (isOn) "fas fa-check text-success" else "fas fa-times text-danger"
            i {addClass("$icon mr-1") }
            text(labelModel.data)
            onClick {
                model.data = !isOn
                it.preventDefault()
            }
        }

}