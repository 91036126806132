package csaware.threats

import dk.rheasoft.csaware.api.timestampNow
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.ThreatObservation
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier

/**
 * Example fot listing threats returned by backend.
 *
 * Currently loads a "new" threats on button click.
 */
class ThreatsTableCurrentActive() : ThreatsTableBase(i18nText(CsawareMessages::nav_threats)) {

    override fun loadData() {
        // clear any tootltip,popovers
        BootstrapTooltipModifier.remove()
        BootstrapPopoverModifier.remove()
        CsawareServices.alerts.clearAlerts()

        val offset = pageSize * (pager.currentPage - 1)
        if (!filter.isFilter()) {
            CsawareServices.backend.getThreatsCurrentActive(timestampNow(), offset, pageSize, this::receiveData)
        } else {
            CsawareServices.backend.getThreatsCurrentActiveWithFilter(timestampNow(), filter, offset, pageSize, this::receiveData)
        }
    }

}