package csaware.threats

import dk.rheasoft.csaware.api.ThreatState
import csaware.messages.CsawareMessages
import kafffe.bootstrap.form.FormGroupDropdown
import kafffe.core.Model
import kafffe.core.kafffenv

/**
 * Dropdown for ThreatState, translates back and forth enum value to localized display name
 */
class ThreatStateDropdown(id: String,
                          labelModel: Model<String>,
                          valueModel: Model<ThreatState>,
                          choicesModel: Model<List<ThreatState>> = ThreatStateDropdown.choicesModel(valueModel.data)
) : FormGroupDropdown<ThreatState>(id, labelModel, valueModel, choicesModel) {

    override fun display(choice: ThreatState): String = CsawareMessages.get().threatState(choice)
    override fun valueFromString(strValue: String): ThreatState = ThreatState.valueOf(strValue)
    override fun valueToString(value: ThreatState): String = value.toString()

    companion object {
        fun choices(currentState: ThreatState): List<ThreatState> {
            if (kafffenv.restrictStates) {
                return when (currentState) {
                    ThreatState.Active -> listOf(ThreatState.Active, ThreatState.Ignored, ThreatState.Resolved)
                    ThreatState.Ignored -> listOf(ThreatState.Active, ThreatState.Ignored, ThreatState.Resolved)
                    ThreatState.Resolved -> listOf(ThreatState.Active, ThreatState.Ignored, ThreatState.Resolved)
                    ThreatState.HealingAwaitDecision -> listOf(ThreatState.HealingAwaitDecision, ThreatState.HealingDeclined, ThreatState.HealingAccepted)
                    ThreatState.HealingAccepted -> listOf(ThreatState.HealingAccepted, ThreatState.HealingDeclined, ThreatState.HealingAwaitDecision)
                    ThreatState.HealingDeclined -> listOf(ThreatState.Ignored, ThreatState.Resolved, ThreatState.HealingDeclined, ThreatState.HealingAccepted, ThreatState.HealingAwaitDecision)
                    ThreatState.HealingInProgress -> listOf(ThreatState.HealingInProgress)
                    ThreatState.HealingFailed -> listOf(ThreatState.Active, ThreatState.Ignored, ThreatState.Resolved, ThreatState.HealingDeclined, ThreatState.HealingAccepted, ThreatState.HealingFailed)
                    ThreatState.Healed -> listOf(ThreatState.Healed, ThreatState.Resolved, ThreatState.Active)
                    else -> ThreatState.values().toList()
                }
            } else {
                return ThreatState.values().toList()
            }
        }

        fun choicesModel(currentState: ThreatState): Model<List<ThreatState>> = Model.ofGet { choices(currentState) }
    }
}