package csaware.main

import csaware.comm.CSAwareBackend
import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.UserRole
import dk.rheasoft.csaware.api.timestampNow
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel
import kafffe.core.kafffenv
import kotlin.browser.window

object UserInformation {
    private var currentCache: User = User("dummy", "dummy", "dummy", "dummy", setOf());
    private var cachedAt = timestampNow().getTime() - 200000;

    val current: User
        get() {
            if ((timestampNow().getTime() - cachedAt) > 180000) {
                loadCurrent {}
            }
            return currentCache
        }

    fun loadCurrent(block: () -> Unit) {
        CSAwareBackend(UserInformation::errorHandler).getCurrentUser {
            currentCache = it
            cachedAt = timestampNow().getTime()
            block()
        }
    }

    fun hasRole(role: UserRole) = !kafffenv.frontendAuth || current.roles.contains(role)

    fun hasAnyRole(vararg roles: UserRole) = roles.any { hasRole(it) }
    fun hasAnyRole(roles: Iterable<UserRole>) = roles.any { hasRole(it) }

    private fun errorHandler(code: Int, text: String) {
        window.alert("User info failed: ($code) ${text}");
    }

    val systemAdministrators: Model<List<User>> = RefreshingCacheModel<List<User>>(::refreshSystemAdministrators, listOf())

    private fun refreshSystemAdministrators(model: Model<List<User>>) {
        if (hasAnyRole(UserRole.SystemAdministrator, UserRole.Viewer)) {
            CSAwareBackend(UserInformation::errorHandler).getUsersByRole(0, 200, UserRole.SystemAdministrator) {
                model.data = it.result
            }
        } else {
            model.data = listOf()
        }
    }

    public fun expireModels() {
        (systemAdministrators as RefreshingCacheModel).expire()
        @Suppress("UNUSED_VARIABLE") val test = systemAdministrators.data
    }

}