package csaware.systemdepend.config

import api.Cardinality
import api.FieldType
import api.SystemDependencyConfig
import api.SystemDependencyField
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.*

class TypeToShapeMapEditor(model: Model<Map<String, String>>, val modelConfig: Model<SystemDependencyConfig>) : KafffeComponentWithModel<Map<String, String>>(model), FormValueProvider {

    val currentMap: MutableMap<String, String> = model.data.toMutableMap()
    val localNodeTypes = modelConfig.data.valueSets["node_type"]
    val shapes = listOf("cylinder_shape", "cloud_shape", "ellipse_shape", "rectangle_shape", "triangle_shape")
    val defaultShape = "rectangle_shape"

    override fun updateValueModel() {
        model.data = currentMap.toMap()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        val idWidth = "24rem"
        return div{
            addClass("form-group")
            div {
                addClass("list-group")
                localNodeTypes?.forEach { nodeType: String ->
                    div {
                        addClass("form-inline")
                        div {
                            addClass("form-control")
                            withElement {
                                style.width = idWidth
                                style.maxWidth = idWidth
                                setAttribute("readOnly", "true")
                            }
                            text(nodeType)
                        }
                        select {
                            addClass("form-control")
                            withElement {
                                value = currentMap[nodeType]!!
                                onchange = {
                                    currentMap[nodeType] = value
                                    it
                                }
                            }
                            for (shape in shapes) {
                                option {
                                    withElement {
                                        value = shape
                                        selected = (shape == currentMap[nodeType])
                                    }
                                    text(shape)
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    init {
        localNodeTypes?.forEach {
            if (it !in currentMap) {
                currentMap[it] = defaultShape
            }
        }
    }
}