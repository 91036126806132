package csaware.infoshare

import api.TrafficLightProtocol
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.*
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import kotlin.reflect.KProperty1

class TrafficLightControl(val idInput: String, valueModel: Model<TrafficLightProtocol>) : KafffeComponentWithModel<TrafficLightProtocol>(valueModel), FormValueProvider {
    var currentTlp: TrafficLightProtocol = valueModel.data

    override fun updateValueModel() {
        model.data = currentTlp
    }

    private var btns: MutableMap<TrafficLightProtocol, HTMLButtonElement> = mutableMapOf()

    private fun changeValue(tlp: TrafficLightProtocol) {
        clear(btns[currentTlp])
        currentTlp = tlp
        mark(btns[currentTlp])
    }

    private fun clear(btn: HTMLButtonElement?) {
        btn?.innerHTML = "&nbsp;"
    }

    private fun mark(btn: HTMLButtonElement?) {
        btn?.innerHTML = "<i class='fas fa-user-check'></i>"
    }

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                addClass("btn-group")
                btn(TrafficLightProtocol.white, "btn-default")
                btn(TrafficLightProtocol.green, "btn-success")
                btn(TrafficLightProtocol.amber, "btn-warning")
                btn(TrafficLightProtocol.red, "btn-danger")
                withElement {
                    changeValue(currentTlp)
                }
            }

    private fun KafffeHtml<HTMLDivElement>.btn(tlp: TrafficLightProtocol, btnClass: String) {
        button {
            withElement {
                btns[tlp] = this
                type = "button"
                addClass("btn $btnClass tlp-$tlp")
                style.width = "3em"
                onclick = { changeValue(tlp) }
            }
        }
    }
}

// DSL function for form component consumer DSL
fun <T : Any, F : Any> FormComponentConsumer<T, F>.tlpControl(idInput: String, valueModel: Model<TrafficLightProtocol>): TrafficLightControl {
    return TrafficLightControl(idInput, valueModel).also { addChild(it) }
}

/**
 * Property based
 */
fun <T : Any, F : Any> FormComponentConsumer<T, F>.tlpControl(property: KProperty1<T, TrafficLightProtocol>): TrafficLightControl {
    return TrafficLightControl(property.name, model.property(property)).also { addChild(it) }
}