package csaware.threats

import csaware.main.CsawareServices
import csaware.main.navigateTo
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import org.w3c.dom.Window
import kotlin.browser.window

open class WhereLink(val whereSightedRefs: Set<String>): KafffeComponent() {
    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div{
            val namesFromIds = CsawareServices.systemDependencies.namesFromIds(whereSightedRefs)
            namesFromIds.forEachIndexed{ index: Int, name: String ->
                span{
                    u {
                        text(name)
                    }
                    withElement {
                        style.cursor = "pointer"
                        style.whiteSpace = "nowrap"
                        onclick = {
                            onclick(name)
                        }
                    }
                }
                if (index < namesFromIds.size - 1) {
                    text(", ")
                }
            }
        }

    open fun onclick(name: String) {
        navigateTo("root/systemDependencies/$name")
    }
}