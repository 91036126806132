package csaware.overview

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.updateInfoCount
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.threats.*
import csaware.translation.TranslationModel
import dk.rheasoft.csaware.api.*
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.core.*
import kafffe.messages.Messages.Companion.formatDate
import kafffe.messages.Messages.Companion.formatDateTime
import org.w3c.dom.HTMLTableCellElement
import kotlin.browser.window

class ThreatDashBoard(val config: UserConfiguration = UserConfiguration.default) : KafffeComponent() {

    val threatTypeListModel = Model.of(listOf<ThreatObservation>())

    var currentThreats: ThreatOverview = ThreatOverview(timestampNow(), listOf())
    var historyThreats: MutableMap<Timestamp, ThreatOverview> = mutableMapOf()

    var currentShownTime: Model<Timestamp> = Model.of(currentThreats.activeAt)
    var dateLabelModel: Model<String> = currentShownTime.func(getData = { it.data.formatDate() })

    val filter = ThreatFilterData();

    // Child Components
    val graph = addChild(DartGraph(clickHandler = { _, type -> filter.groupModel.data = type ?: filter.all }))
    val dateLabel = addChild(Label(dateLabelModel))


    val playButtons = addChild(PlayButtons(::playHandler))


    val typeTableTitle = addChild(Label(Model.ofGet { CsawareMessages.get().threat_topTitle + if (filter.group == filter.all) "" else ": ${filter.group}" }))
    val typeTable = addChild(BootstrapTable<ThreatObservation>(threatTypeListModel).apply {
        rowClickHandler = { ThreatStateChangeDlg.show(it) }

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))

        colEx(i18nText(CsawareMessages::threat_state), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.bgColor = UserConfiguration.default.severityColorMap[rowData.severity].rgbHex
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })
        col(i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.formatDateTime()) })
        col(i18nText(CsawareMessages::threat_assignee),
                { Label(it.assignee) },
                { ColumnHeaderDropdownFilter(it, filter.assigneeModel, filter.all, filter.assigneeChoices) }
        )
        col(i18nText(CsawareMessages::threat_group),
                { Label(it.threatGroup) },
                { ColumnHeaderDropdownFilter(it, filter.groupModel, filter.all, filter.groupChoices) }
        )
        col(i18nText(CsawareMessages::threat_where),
                { WhereLink(it.whereSightedRefs) },
                { ColumnHeaderDropdownFilter(it, filter.whereModel, filter.all, filter.whereChoices) }
        ).apply {
            rowClick = false
        }

        colEx(i18nText(CsawareMessages::name), { observation, cell ->
            val desc = observation.descriptionSoMe
            val translationModel = TranslationModel(Model.of(desc))
            Popovers.description({ translationModel.translation }).modify(cell)
            Label(observation.nameSoMe)
        })
    })

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            addClass("vgap-3") // vertical gap between children
            div {
                addClass("hgap-3") // horisontal gap between children
                // add(loadButton.html)
            }
            div {
                addClass("d-flex flex-wrap hgap-4 vgap-4")
                div {
                    addClass("card text-center text-white bg-secondary")
                    h3 {
                        addClass("card-header csaware-field text-light")
                        add(dateLabel.html)
                    }
                    // addClass("mr-auto")
                    div {
                        addClass("card-body")
                        add(graph.html)
                    }
                    div {
                        addClass("card-footer text-center  csaware-field  text-light")
                        add(playButtons.html)
                    }
                }
                div {
                    addClass("card text-white bg-secondary flex-fill")
                    element?.style?.width = "min-content"
                    h3 {
                        addClass("card-header text-center csaware-field  text-light")
                        add(typeTableTitle.html)
                    }
                    div {
                        addClass("card-body")
                        add(typeTable.html)
                    }
                }
            }
        }
    }

    init {
        graph.threats = ThreatOverview(timestampNow(), listOf())
        loadData()
        loadHistoryData()
    }

    private fun loadData() {
        updateInfoCount()
        CsawareServices.alerts.clearAlerts()
        // clear any tootltip,popovers
        BootstrapTooltipModifier.remove()
        BootstrapPopoverModifier.remove()
        CsawareServices.backend.getThreatGroups {
            graph.threatGroupsModel.data = it.toList()
        }
        CsawareServices.backend.getThreatsOverview(timestampNow()) {
            currentThreats = it
            currentShownTime.data = it.activeAt
            graph.threats = it
            graph.redraw()
            loadCurrentType()
        }
    }

    private fun loadHistoryData() {
        for (day in 1..config.nofHistoryDays) {
            val time = timestampNow().plusDays(-day)
            CsawareServices.backend.getThreatsOverview(time) {
                historyThreats[it.activeAt] = it
            }
        }
    }

    private fun loadCurrentType() {
        CsawareServices.alerts.clearAlerts()
        if (!filter.isFilter()) {
            CsawareServices.backend.getThreatsCurrentActive(currentShownTime.data, 0, config.topNumberOfThreats) {
                threatTypeListModel.data = it.result
                typeTableTitle.rerender()
            }
        } else {
            CsawareServices.backend.getThreatsCurrentActiveWithFilter(currentShownTime.data, filter, 0, config.topNumberOfThreats) {
                threatTypeListModel.data = it.result
                typeTableTitle.rerender()
            }
        }
    }

    // Web Socket updateable
    private val onServerUpdate: (String) -> Unit = { serverUpdated(it) }

    protected fun serverUpdated(msg: String) {
        println(msg)
        loadData()
    }

    private val changeListener = ModelChangeListener { loadCurrentType() }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
        filter.asModel.listeners.add(changeListener)
    }

    override fun detach() {
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        filter.asModel.listeners.remove(changeListener)
        stopPlay()
        super.detach()
    }

    // Play History
    var timerHandle: Int = -1

    fun playHandler(action: PlayButtons.PlayAction) {
        when (action) {
            PlayButtons.PlayAction.play -> startPlay()
            PlayButtons.PlayAction.stop -> stopPlay()

            PlayButtons.PlayAction.step_forward -> next()
            PlayButtons.PlayAction.step_backward -> prev()

            PlayButtons.PlayAction.fast_forward -> last()
            PlayButtons.PlayAction.fast_backward -> first()
        }
    }

    fun startPlay() {
        timerHandle = window.setInterval({ next() }, config.playDelayMs)
        playButtons.playing = true
    }

    fun stopPlay() {
        if (timerHandle != -1)
            window.clearInterval(timerHandle)
        timerHandle = -1
        playButtons.playing = false
    }


    fun showDate(nextDate: Timestamp) {
        val next = historyThreats[nextDate] ?: currentThreats
        graph.threats = next
        graph.redraw()
        currentShownTime.data = next.activeAt
//        if (!playButtons.playing) {
        // reload details
        loadCurrentType()
//        }
    }

    fun allDatesSorted(): MutableList<Timestamp> {
        var allDates = mutableListOf<Timestamp>()
        allDates.addAll(historyThreats.keys)
        allDates.add(currentThreats.activeAt)
        allDates.sortBy { it.getTime() }
        return allDates
    }

    fun first() {
        showDate(allDatesSorted().first())
    }

    fun last() {
        // we want to do a reload at this time
        loadData()
    }

    fun prev() {
        var prevDate: Timestamp = currentShownTime.data
        var allDates = allDatesSorted()
        var nextDate = allDates.reversed().firstOrNull { prevDate.isAfter(it) } ?: allDates.last()
        println("${prevDate.formatDateTime()} ${nextDate.formatDateTime()}")
        showDate(nextDate)
    }

    fun next() {
        var prevDate: Timestamp = currentShownTime.data
        var allDates = allDatesSorted()
        var nextDate = allDates.firstOrNull { it.isAfter(prevDate) } ?: allDates.first()
        showDate(nextDate)
    }

}