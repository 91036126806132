package csaware.test

import csaware.main.CsawareServices
import kafffe.messages.NavLanguageSelector
import kafffe.bootstrap.*
import kafffe.bootstrap.navigation.Nav
import kafffe.core.Model
import kafffe.core.NavigationElement
import kafffe.core.NavigationPath
import kafffe.messages.Messages
import kafffe.messages.i18nText
import kotlin.browser.window

fun init() {
    CsawareServices.initServices()
    CsawareServices.systemDependencies.expireModels()
    window.onload = {
        val rootNavigation = NavigationElement.create("root") {
            component("bundle") { BundleTestPage() }
            component("selfhealing") { SelfHealingTestPage() }
            component("infoshare") { InfoShareTestPage() }
        }
        BootstrapRoot().apply {
            val container = BootstrapContainer.fluid()
            rootNavigation.componentConsumer = { container.replaceContent(it) }
            addChild(Nav.create(rootNavigation) {
                addExpand(ResponsiveSize.md)
                style = ColorStrength.light
                //backgroundColor = "#4bb7e5"
                background = BasicColor.secondary
                brand(" -- Test", "images/BrandLogo.png")
                toggle("menuToggle")
                toggleBlock("menuToggle") {
                    item(Model.of("Data Analysis"), NavigationPath.fromString("root/bundle"), "fas fa-asterisk")
                    item(Model.of("Self Healing"), NavigationPath.fromString("root/selfhealing"), "fas fa-hospital")
                    item(Model.of("Info Share"), NavigationPath.fromString("root/infoshare"), "fas fa-info")
                    addChild(NavLanguageSelector(i18nText(Messages::languageSelect)))
                }
            })
            addChild(container)
            attach()
        }
        rootNavigation.navigateTo(NavigationPath.fromString("root/pie"))
    }
}


fun main(args: Array<String>) {
    println("Csaware Start")
}

