package csaware.systemdepend

import api.SystemDependencyResource
import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.CsawareServices.backend
import csaware.main.CsawareServices.systemDependencies
import dk.rheasoft.csaware.api.ThreatOverview
import dk.rheasoft.csaware.api.timestampNow
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.bootstrapCol
import kafffe.bootstrap.bootstrapRow
import kafffe.core.*

class SystemDependencies : KafffeComponent() {

    val titleDetailsLabel = addChild(Label(Model.of("")))

    private var resources: Model<List<SystemDependencyResource>> = systemDependencies.model
    private var threatSummary: Model<ThreatOverview> = Model.of(ThreatOverview(timestampNow(), listOf()))


    val graph = addChild(SystemGraph(resources, threatSummary))
    val details = addChild(SystemDetails(graph.selectionModel, graph.hightlightModel))

    init {
        loadThreats()
    }

    private val onResourcesUpdate = ModelChangeListener {
        loadThreats()
    }

    private val onServerUpdate: (String) -> Unit = {
        CsawareServices.alerts.clearAlerts()
        loadThreats()
        loadSelectedThreats()
    }

    private val onSelectionChange = ModelChangeListener(::loadSelectedThreats)


    protected fun loadThreats() {
        backend.getSystemResourceOverview(timestampNow()) {
            threatSummary.data = it
        }
    }

    public fun loadSelectedThreats() {
        details.threatListModel.data = listOf()
        backend.getSystemResourceThreatsCurrentActive(timestampNow(), graph.selectionModel.data.id, offset = 0, pagesize = 4) {
            details.threatListModel.data = it.result
        }
    }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
        graph.selectionModel.listeners.add(onSelectionChange)
        resources.listeners.add(onResourcesUpdate)
    }

    override fun detach() {
        graph.selectionModel.listeners.remove(onSelectionChange)
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        resources.listeners.remove(onResourcesUpdate)
        super.detach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return bootstrapRow() {
            addClass("pt-2")
            bootstrapCol(ColWidth(ResponsiveSize.sm, 8)) {
                add(graph.html)
            }
            bootstrapCol(ColWidth(ResponsiveSize.sm, 4)) {
                addClass("pl-sm-0") // less gutter
                add(details.html)
            }
        }
    }


}