package csaware.user

import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.User
import dk.rheasoft.csaware.api.UserRole
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import org.w3c.dom.HTMLInputElement
import kotlin.dom.addClass
import kotlin.math.ceil

class UserManagement : KafffeComponent() {
    val titleLabel = addChild(Label(i18nText(CsawareMessages::user_management)))

    val table = addChild(BootstrapTable.create<User>(listOf()) {
        rowClickHandler = {user -> showUserDlg(user, allowDelete = true)}

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover bg-secondary text-white"))

        col(i18nText(CsawareMessages::user_subject), { Label(it.subject) })
        col(i18nText(CsawareMessages::user_email), { Label(it.email) })
        col(i18nText(CsawareMessages::user_firstName), { Label(it.firstName) })
        col(i18nText(CsawareMessages::user_lastName), { Label(it.lastName) })
        col(i18nText(CsawareMessages::user_roles), { Label(it.roles.toString()) })
    })

    var pageSize: Int = 10

    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager))
    private var filterInput: HTMLInputElement? = null

    init {
        pager.changeListeners.add({ loadData() })
        loadData()
    }

    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        val filter = filterInput?.value ?: ""
        CsawareServices.backend.getUsers(offset, pageSize, this::receiveData, filter)
    }

    private fun receiveData(response: QueryResult<User>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    fun showUserDlg(user: User, allowDelete: Boolean) {
        UserEditDlg(user, allowDelete,  this::loadData).attach()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            div {
                addClass("card mt-2 bg-secondary text-light")
                div {
                    addClass("h4")
                    addClass("card-header csaware-field text-light")
                    add(titleLabel.html.also { it.addClass("mr-auto") })
                    div {
                        addClass("btn-group float-right")
                        addClass("sd_search_bar")
                        filterInput = input {
                            addClass("sd_search_input bg-info text-white")
                            withElement {
                                type = "text"
                                onfocus = {
                                    it
                                }
                                oninput = {
                                    // Todo limit time treshold filter before reload
                                    loadData()
                                }
                                onkeydown = {
                                    when (it.key) {
                                        "Escape" -> filterInput?.blur() // TODO CLEAR FILTER
                                        "Enter" -> filterInput?.blur() // todo filter
                                    }
                                    it
                                }
                            }
                        }.element
                        button {
                            onclick {
                                filterInput?.focus()
                            }
                            addClass("sd_search_icon btn")
                            i {
                                addClass("fas fa-search")
                            }
                        }
                    }
                }
                add(table.html)
                div {
                    addClass("card-footer csaware-field text-light")
                    add(paginator.html)
                    add(BootstrapButton(i18nText(CsawareMessages::user_new)) { showUserDlg(User("-", "", "", "", setOf(UserRole.Viewer)), allowDelete = false) }.html)
                }
            }
        }
    }
}