package csaware.systemdepend.config

import api.Cardinality
import api.FieldType
import api.SystemDependencyConfig
import api.SystemDependencyField
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model

class FieldsEditor(model: Model<List<SystemDependencyField>>, val modelConfig: Model<SystemDependencyConfig>) : KafffeComponentWithModel<List<SystemDependencyField>>(model), FormValueProvider {
    val currentFields: MutableList<SystemDependencyField> = model.data.toMutableList()

    private fun indexOfField(field: SystemDependencyField) = currentFields.indexOfFirst { s -> s.id == field.id }

    private fun updateField(field: SystemDependencyField) {
        val ix = indexOfField(field)
        if (ix == -1) {
            currentFields.add(field)
        } else {
            currentFields[ix] = field
        }
    }

    private fun removeField(field: SystemDependencyField) {
        val ix = indexOfField(field)
        if (ix != -1) {
            currentFields.removeAt(ix)
        }
    }

    private fun moveField(field: SystemDependencyField, offset: Int) {
        val ix = indexOfField(field)
        val newIx = ix + offset
        if (ix != -1 && newIx in (0 until currentFields.size)) {
            currentFields.removeAt(ix)
            currentFields.add(newIx, field)
        }
    }

    override fun updateValueModel() {
        model.data = currentFields.toList()
    }

    private val NO_VALUESET = "Not Restricted"

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        val idWidth = "14rem"
        val singleKeys = CsawareServices.systemDependencies.model.data.flatMap { it.data.keys }.toList()
        val multiKeys = CsawareServices.systemDependencies.model.data.flatMap { it.dataLists.keys }.toList()
        val count: Map<String, Int> = (singleKeys + multiKeys).groupingBy { it }.eachCount()
        return div {
            addClass("form-group")
            div {
                addClass("list-group")
                currentFields.forEach { field ->
                    div {
                        addClass("form-inline")
                        div {
                            addClass("form-control")
                            withElement {
                                style.width = idWidth
                                style.maxWidth = idWidth
                                setAttribute("readOnly", "true")
                            }
                            text(field.id)
                            val usageCount = count[field.id] ?: 0
                            if (usageCount > 0) {
                                sup {
                                    addClass("badge badge-pill badge-warning")
                                    text("$usageCount")
                                }
                            }

                        }
                        input {
                            addClass("form-control")
                            withElement {
                                value = field.label
                                type = "text"
                                onchange = {
                                    if (value.isNotBlank()) {
                                        updateField(SystemDependencyField(field.id, value, field.type, field.cardinality, field.valueSet))
                                    }
                                }
                            }
                        }
                        select {
                            addClass("form-control")
                            withElement {
                                value = field.type.name
                                onchange = {
                                    updateField(SystemDependencyField(field.id, field.label, FieldType.parse(value), field.cardinality, field.valueSet))
                                }
                            }
                            FieldType.values().forEach {
                                option {
                                    withElement {
                                        value = it.name
                                        selected = (it == field.type)
                                    }
                                    text(it.name)
                                }
                            }
                        }
                        select {
                            addClass("form-control")
                            withElement {
                                value = field.cardinality.name
                                onchange = {
                                    updateField(SystemDependencyField(field.id, field.label, field.type, Cardinality.parse(value), field.valueSet))
                                }
                            }
                            Cardinality.values().forEach {
                                option {
                                    withElement {
                                        value = it.name
                                        selected = (it == field.cardinality)
                                    }
                                    text(CsawareMessages.get().system_depend_field_cardinality(it))
                                }
                            }
                        }
                        select {
                            addClass("form-control")
                            withElement {
                                value = field.valueSet ?: NO_VALUESET
                                onchange = {
                                    updateField(SystemDependencyField(field.id, field.label, field.type, field.cardinality, if (value == NO_VALUESET) null else value))
                                }
                            }
                            option {
                                withElement {
                                    value = NO_VALUESET
                                    selected = (field.valueSet == null)
                                }
                            }

                            modelConfig.data.valueSets.keys.forEach {
                                option {
                                    withElement {
                                        value = it
                                        selected = (it == field.valueSet)
                                    }
                                    text(it)
                                }
                            }
                        }
                        span {
                            addClass("btn-group")
                            button {
                                addClass("btn btn-secondary")
                                withElement {
                                    type = "button"
                                    onclick = {
                                        moveField(field, -1)
                                        rerender()
                                    }
                                }
                                i {
                                    addClass("fas fa-arrow-up")
                                }
                            }
                            button {
                                addClass("btn btn-secondary")
                                withElement {
                                    type = "button"
                                    onclick = {
                                        moveField(field, +1)
                                        rerender()
                                    }
                                }
                                i {
                                    addClass("fas fa-arrow-down")
                                }
                            }
                            button {
                                addClass("btn btn-secondary")
                                withElement {
                                    type = "button"
                                    onclick = {
                                        removeField(field)
                                        rerender()
                                    }
                                }
                                i {
                                    addClass("fas fa-trash")
                                }
                            }
                        }

                    }

                }
                div {
                    addClass("form-inline mt-1")
                    val idInput = input {
                        addClass("form-control")
                        withElement {
                            value = ""
                            type = "text"
                            style.width = idWidth
                            style.maxWidth = idWidth
                        }
                    }.element!!
                    val labelInput = input {
                        addClass("form-control")
                        withElement {
                            value = ""
                            type = "text"
                        }
                    }.element!!
                    val typeInput = select {
                        addClass("form-control")
                        withElement {
                        }
                        FieldType.values().forEach {
                            option {
                                element?.value = it.name
                                text(it.name)
                            }
                        }
                    }.element!!
                    val cardinalityInput = select {
                        addClass("form-control")
                        withElement {
                            value = Cardinality.One.name
                        }
                        Cardinality.values().forEach {
                            option {
                                withElement {
                                    value = it.name
                                    selected = (it == Cardinality.One)
                                }
                                text(CsawareMessages.get().system_depend_field_cardinality(it))
                            }
                        }
                    }.element!!
                    val valuesetInput = select {
                        addClass("form-control")
                        withElement {
                            value = NO_VALUESET
                        }
                        option {
                            withElement {
                                value = NO_VALUESET
                                selected = true
                            }
                        }
                        modelConfig.data.valueSets.keys.forEach {
                            option {
                                withElement {
                                    value = it
                                }
                                text(it)
                            }
                        }
                    }.element!!
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            type = "button"
                            onclick = {
                                val id = idInput.value
                                val label = labelInput.value
                                val type = FieldType.parse(typeInput.value)
                                val cardinality = Cardinality.parse(cardinalityInput.value)
                                val valueSet = if (valuesetInput.value == NO_VALUESET) null else valuesetInput.value
                                if (id.isNotBlank() && label.isNotBlank()) {
                                    if (id !in currentFields.map { it.id }) {
                                        updateField(SystemDependencyField(id, label, type, cardinality, valueSet))
                                        rerender()
                                    }
                                }
                            }
                        }
                        i {
                            addClass("fas fa-plus")
                        }
                    }

                }

            }
        }
    }

}