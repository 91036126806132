package csaware.threats

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.overview.UserConfiguration
import csaware.translation.StandardTranslation
import csaware.translation.TranslationModel
import dk.rheasoft.csaware.api.QueryResult
import dk.rheasoft.csaware.api.ThreatObservation
import dk.rheasoft.csaware.api.timestampNow
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.modifier.BootstrapPopoverModifier
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.messages.Messages
import kafffe.messages.Messages.Companion.formatDateTime
import kafffe.messages.i18nText
import org.w3c.dom.HTMLTableCellElement
import kotlin.dom.addClass
import kotlin.math.ceil

/**
 * Example fot listing threats returned by backend.
 *
 * Currently loads a "new" threats on button click.
 */
open class ThreatsTableBase(val titleModel: Model<String>, val showEndActive: Boolean = false) : KafffeComponent() {

    val titleLabel = addChild(Label(titleModel))

    val filter = ThreatFilterData();

    val table = addChild(BootstrapTable.create<ThreatObservation>(listOf()) {
        rowClickHandler = { ThreatStateChangeDlg.show(it) }

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover bg-secondary text-white"))
        colEx(i18nText(CsawareMessages::threat_state), fun(rowData: ThreatObservation, cell: HTMLTableCellElement): ThreatStateSymbol {
            cell.bgColor = UserConfiguration.default.severityColorMap[rowData.severity].rgbHex
            return ThreatStateSymbol(Model.of(rowData), includeSeverityText = true)
        })

        if (showEndActive) {
            col(i18nText(CsawareMessages::threat_endActive), { Label(it.endActive.formatDateTime()) })
        }
        col(i18nText(CsawareMessages::threat_firstObserved), { Label(it.firstObserved.formatDateTime()) })
        col(i18nText(CsawareMessages::id), { Label(it.id) })
        col(i18nText(CsawareMessages::threat_stixtype), { Label(CsawareMessages.get().threatStixType(it.stixType)) })
        col(i18nText(CsawareMessages::threat_group), { Label(it.threatGroup) }, { ColumnHeaderDropdownFilter(it, filter.groupModel, filter.all, filter.groupChoices) })
        col(i18nText(CsawareMessages::threat_assignee), { Label(it.assignee) }, { ColumnHeaderDropdownFilter(it, filter.assigneeModel, filter.all, filter.assigneeChoices) })
        col(i18nText(CsawareMessages::threat_where),
                { WhereLink(it.whereSightedRefs) },
                { ColumnHeaderDropdownFilter(it, filter.whereModel, filter.all, filter.whereChoices) }).apply { rowClick = false }
        col(i18nText(CsawareMessages::name), { Label(it.nameSoMe) })
        colEx(i18nText(CsawareMessages::description), { observation, cell ->
            val desc = observation.descriptionSoMe
            val elipsis = if (desc.length > 64) desc.substring(0, 60) + " ..." else desc
            val translationModel = TranslationModel(Model.of(desc))
            Popovers.description({ translationModel.translation }).modify(cell)
            val label = Label(elipsis)
            translateDescription(desc, label)
            label
        })
        // Count not applicable for the current use cases  col(i18nText(CsawareMessages::count), { Label(it.count.toString()) })
    })

    private fun translateDescription(desc: String, label: Label) {
        StandardTranslation.translate(desc) { desc2 ->
            val elipsis = if (desc2.length > 64) desc2.substring(0, 60) + " ..." else desc2
            label.text = elipsis
        }
    }

    var pageSize: Int = 10

    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager).apply {
        addClass("card-footer csaware-field text-light")
        addClass("m-0")
        addClass("pl-2")
    })

    init {
        pager.changeListeners.add({ loadData() })
    }

    init {
        loadData()
    }

    val loadButton = addChild(
            BootstrapButton(i18nText(Messages::load), onClick = { loadData() }).apply {
                iconClasses = "fas fa-sync"
                color = BasicColor.primary
            })

    open fun loadData() {
        CsawareServices.alerts.clearAlerts()
        // clear any tootltip,popovers
        BootstrapTooltipModifier.remove()
        BootstrapPopoverModifier.remove()

        val offset = pageSize * (pager.currentPage - 1)
        if (!filter.isFilter()) {
            CsawareServices.backend.getThreatsCurrentActive(timestampNow(), offset, pageSize, this::receiveData)
        } else {
            CsawareServices.backend.getThreatsCurrentActiveWithFilter(timestampNow(), filter, offset, pageSize, this::receiveData)
        }
    }

    fun receiveData(response: QueryResult<ThreatObservation>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                div {
                    addClass("card mt-2 bg-secondary text-light")
                    div {
                        addClass("h4")
                        addClass("card-header csaware-field text-light")
                        addClass("form-inline")
                        addClass("d-flex")
                        add(titleLabel.html.also { it.addClass("mr-auto") })
                        if (showEndActive) {
                            a {
                                addClass("btn btn-info ml-1")
                                element?.href = "/threats/closed.export"
                                i { addClass("fas fa-file-excel") }
                                +" "
                                i { addClass("fas fa-download") }
                                +" "
                            }
                        }
                    }
                    add(table.html)
                    add(paginator.html)

                }
            }

    private val onServerUpdate: (String) -> Unit = { serverUpdated(it) }

    private val changeListener = ModelChangeListener { serverUpdated("change") }

    protected open fun serverUpdated(msg: String) {
        println(msg)
        loadData()
    }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
        filter.asModel.listeners.add(changeListener)
    }

    override fun detach() {
        filter.asModel.listeners.remove(changeListener)
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        super.detach()
    }
}