package csaware.translation

import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut

class TranslationField(val text: String, val defaultCssClass: String = "csaware-field") : KafffeComponent() {
    var lines: Int by rerenderOnChange(1)

    private var _translation: String? = null
    val translation : String get() = _translation?: text

    init {
        translate()
    }
    fun translate() {
        StandardTranslation.translate(text) {
            _translation = it
            rerender()
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass(defaultCssClass)
            if (lines >= 1) {
                addClass("csaware-lines csaware-lines-$lines")
                withElement {
                    if (lines == 1) {
                        style.overflowY = "hidden"
                    } else {
                        style.overflowY = "auto"
                        style.resize = "vertical"
                    }
                }
            }
            text(translation)
        }

}