package csaware.messages

import kafffe.messages.Messages_it

open class CsawareMessages_it : Messages_it(), CsawareMessages {
    override val translation_automatic: String = "Traduzione automatica"

    override val nav_overview = "Panoramica"
    override val nav_threats = "Minacce"
    override val nav_threatsClosed = "Minacce Risolte"
    override val nav_config = "Configurazione"
    override val threat_group = "Gruppo"
    override val threat_group_all = "Tutte"
    override val threat_nobody = "Nobody"
    override val threat_state = "Stato"
    override val threat_state_initator = "Chi"
    override val threat_state_history = "Storico"
    override val threat_state_comment = "Commento"
    override val threat_course_of_action = "Sequenza delle azioni"
    override val threat_where = "Dove"
    override val threat_assignee = "Assegnatario"

    override val threat_stixtype = "Tipo"
    override val threat_stixtype_campaign = "Campagna"
    override val threat_stixtype_report = "Report"
    override val threat_stixtype_vulnerability = "Vulnerabilità"
    override val threat_stixtype_malware = "Malware"
    override val threat_stixtype_attack_pattern = "Attack Pattern"
    override val threat_stixtype_tool = "Strumento"
    override val threat_stixtype_unknown = "Non identificato"

    override val threat_observed_data_objects = "Dati Analizzati"
    override val threat_observed_data_id= "Id"
    override val threat_observed_data_type= "Tipo"
    override val threat_observed_data_data = "Dati"

    override val threat_subtype = "Sottotipo"
    override val threat_firstObserved = "Prima osservazione"
    override val threat_endActive = "Risolto il"
    override val threat_newState = "Cambio Stato"
    override val threat_selfHealingConfirm = "Conferma il Self Healing"

    override val threat_topTitle = "Principali Minacce"

    override val severity = "Severità"
    override val count = "Numerosità"

    override val threat_level_5_name = "Critica"
    override val threat_level_5_text = "Un attacco è previsto imminentemente."
    override val threat_level_4_name = "Severa"
    override val threat_level_4_text = "Un attacco è altamente probabile."
    override val threat_level_3_name = "Elevata"
    override val threat_level_3_text = "Un attacco è probabilie."
    override val threat_level_2_name = "Moderata"
    override val threat_level_2_text = "Un attacco è possibile, ma non probabile."
    override val threat_level_1_name = "Basso"
    override val threat_level_1_text = "Un attacco non è probabile."
    override val threat_level_0_name = ""
    override val threat_level_0_text = ""

    override val ThreatState_Active = "Attiva"
    override val ThreatState_Resolved = "Risolta"
    override val ThreatState_Ignored = "Ignorata"
    override val ThreatState_HealingAwaitDecision = "Necessaria una decisione per Self Healing "
    override val ThreatState_HealingAccepted = "Accetta Self Healing"
    override val ThreatState_HealingDeclined = "Rifiuta Self Healing"
    override val ThreatState_Healed = "Self Healing Effettuato"
    override val ThreatState_HealingFailed = "Self Healing Fallito"
    override val ThreatState_HealingInProgress = "Self Healed in corso"
    override val ThreatState_Unknown = "Sconosciuto"

    override val user_management = "Gestione Utente"
    override val user_edit = "Modifica Utente"
    override val user_new = "Nuovo Utente"
    override val user_subject = "Oggetto"
    override val user_email = "Email"
    override val user_firstName = "Nome"
    override val user_lastName = "Cognome"
    override val user_roles = "Ruoli"

    override val informationshare_management = "Condivisione delle informazioni"
    override val informationshare_short_description = """Information sharing referrs to sharing of cybersecurity information with cybersecurity professionals outside your organization. 
Examples of such professionals may be computer security incident response teams (CSIRTs) your organization is collaborating with.

The purpose of sharing this information with cybersecurity professionals is for them to be better able to assess and analyze cybersecurity issues within organizations and to be able to set it in context with information gathered from other sources in order to be able to devise better mitigation and prevention mechanisms, which in turn may help your organization to better protect against attacks in the future.

If you decide to share, the information will be posted on a protected repository. Only people and organizations that have been authorized by your organization will be able to access the information."""
    override val informationshare_edit = "Modifica la Condivisione delle Informazioni"
    override val informationshare_id = "Id"
    override val informationshare_description = "Descrizione"
    override val informationshare_state = "Stato"
    override val informationshare_create = "Created"
    override val informationshare_modified = "Ultima modifica"
    override val informationshare_initiator = "Chi"
    override val informationshare_history = "Storico"
    override val informationshare_summary = "Summary"

    override val ShareState_AwaitDecision = "Conferma condivisione"
    override val ShareState_Accept = "accetta"
    override val ShareState_Decline = "rifiuta"
    override val ShareState_Unknown = "non definita"

    override val system_dependencies = "Sistema"

    override val system_depend_id = "Id"
    override val system_depend_name  = "Nome"
    override val system_depend_description= "Descrizione"
    override val system_depend_connectedFrom= "Connesso da"
    override val system_depend_connectedTo= "Connesso a"
    override val system_depend_infoflow= "Flusso di informazioni"
    override val system_depend_category= "Categoria"
    override val system_depend_fields= "Campi"
    override val system_depend_fields_valueset = "Insieme di Valori"
    override val system_depend_fields_valueset_values = "Valori"
    override val system_depend_fields_to_shape_mapping = "NodeType to shape"
    override val system_depend_layout = "Layout"
    override val system_depend_layoutDirection = "Layout Direzione"
    override val system_depend_layout_horizontal = "Orizzontale"
    override val system_depend_layout_vertical = "Verticale"
    override val system_depend_layout_roots = "Roots"
    override val system_depend_layout_user_root = "Layout root"
    override val system_depend_layout_default_root = "Default layout"
    override val system_depend_spacing = "Spacing"

    override val system_depend_action_search = "Cerca Risorse di Sistema"
    override val system_depend_action_new = "Crea Nuovo"
    override val system_depend_action_edit = "Modifica"
    override val system_depend_action_delete = "Cancella"
    override val system_depend_action_delete_confirm_title = "Cancella Risorse di Sistema"
    override val system_depend_action_config = "Configurazione"
    override val system_depend_action_import = "Importa"
    override val system_depend_action_export = "Esporta"
    override val system_depend_action_print = "Stampa"
    override val system_depend_label_all = "Tutto"

    override val sign_out = "Check Out"
    override val sign_out_disclaimer = """
        |The CS-AWARE Visualisation session as been cleared,  
        |you will have to logout of the external Authorisation Provider,  
        |if you do not want to be automatically signed in again. 
        |""".trimMargin()
    override val sign_out_link="Go to Your Authorisation Provider to Sign Out"

    override val mail_dialog_title = "Send Email"
    override val mail_to = "To"
    override val mail_from = "From"
    override val mail_subject = "Subject"
    override val mail_body = "Body"
    override val mail_send = "Send"
}