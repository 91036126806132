package csaware.messages

import kafffe.messages.Messages_delegate
import kotlin.js.Json

open class CsawareMessages_delegate(json: Json) : Messages_delegate(json), CsawareMessages {
    override val translation_automatic by MapDelegate()

    override val nav_overview by MapDelegate()
    override val nav_threats by MapDelegate()
    override val nav_threatsClosed by MapDelegate()
    override val nav_config by MapDelegate()
    override val threat_group by MapDelegate()
    override val threat_group_all by MapDelegate()
    override val threat_nobody by MapDelegate()
    override val threat_state by MapDelegate()
    override val threat_state_initator by MapDelegate()
    override val threat_state_history by MapDelegate()
    override val threat_state_comment by MapDelegate()
    override val threat_course_of_action by MapDelegate()
    override val threat_where by MapDelegate()
    override val threat_assignee by MapDelegate()

    override val threat_stixtype by MapDelegate()
    override val threat_stixtype_campaign by MapDelegate()
    override val threat_stixtype_report by MapDelegate()
    override val threat_stixtype_vulnerability by MapDelegate()
    override val threat_stixtype_malware by MapDelegate()
    override val threat_stixtype_attack_pattern by MapDelegate()
    override val threat_stixtype_tool by MapDelegate()
    override val threat_stixtype_unknown by MapDelegate()

    override val threat_observed_data_objects by MapDelegate()
    override val threat_observed_data_id by MapDelegate()
    override val threat_observed_data_type by MapDelegate()
    override val threat_observed_data_data by MapDelegate()

    override val threat_subtype by MapDelegate()
    override val threat_firstObserved by MapDelegate()
    override val threat_endActive by MapDelegate()
    override val threat_newState by MapDelegate()
    override val threat_selfHealingConfirm by MapDelegate()

    override val threat_topTitle by MapDelegate()

    override val severity by MapDelegate()
    override val count by MapDelegate()

    override val threat_level_5_name by MapDelegate()
    override val threat_level_5_text by MapDelegate()
    override val threat_level_4_name by MapDelegate()
    override val threat_level_4_text by MapDelegate()
    override val threat_level_3_name by MapDelegate()
    override val threat_level_3_text by MapDelegate()
    override val threat_level_2_name by MapDelegate()
    override val threat_level_2_text by MapDelegate()
    override val threat_level_1_name by MapDelegate()
    override val threat_level_1_text by MapDelegate()
    override val threat_level_0_name by MapDelegate()
    override val threat_level_0_text by MapDelegate()

    override val ThreatState_Active by MapDelegate()
    override val ThreatState_Resolved by MapDelegate()
    override val ThreatState_Ignored by MapDelegate()
    override val ThreatState_HealingAwaitDecision by MapDelegate()
    override val ThreatState_HealingAccepted by MapDelegate()
    override val ThreatState_HealingDeclined by MapDelegate()
    override val ThreatState_Healed by MapDelegate()
    override val ThreatState_HealingFailed by MapDelegate()
    override val ThreatState_HealingInProgress by MapDelegate()
    override val ThreatState_Unknown by MapDelegate()

    override val user_management by MapDelegate()
    override val user_edit by MapDelegate()
    override val user_new by MapDelegate()
    override val user_subject by MapDelegate()
    override val user_email by MapDelegate()
    override val user_firstName by MapDelegate()
    override val user_lastName by MapDelegate()
    override val user_roles by MapDelegate()

    override val informationshare_management by MapDelegate()
    override val informationshare_short_description by MapDelegate()
    override val informationshare_edit by MapDelegate()
    override val informationshare_id by MapDelegate()
    override val informationshare_description by MapDelegate()
    override val informationshare_state by MapDelegate()
    override val informationshare_create by MapDelegate()
    override val informationshare_modified by MapDelegate()
    override val informationshare_initiator by MapDelegate()
    override val informationshare_history by MapDelegate()
    override val informationshare_summary by MapDelegate()

    override val ShareState_AwaitDecision by MapDelegate()
    override val ShareState_Accept by MapDelegate()
    override val ShareState_Decline by MapDelegate()
    override val ShareState_Unknown by MapDelegate()

    override val system_dependencies by MapDelegate()

    override val system_depend_id by MapDelegate()
    override val system_depend_name by MapDelegate()
    override val system_depend_description by MapDelegate()
    override val system_depend_connectedFrom by MapDelegate()
    override val system_depend_connectedTo by MapDelegate()
    override val system_depend_infoflow by MapDelegate()
    override val system_depend_category by MapDelegate()
    override val system_depend_fields by MapDelegate()
    override val system_depend_fields_valueset by MapDelegate()
    override val system_depend_fields_valueset_values by MapDelegate()
    override val system_depend_fields_to_shape_mapping by MapDelegate()

    override val system_depend_layout by MapDelegate()
    override val system_depend_layoutDirection by MapDelegate()
    override val system_depend_layout_horizontal by MapDelegate()
    override val system_depend_layout_vertical by MapDelegate()
    override val system_depend_layout_roots by MapDelegate()
    override val system_depend_layout_user_root by MapDelegate()
    override val system_depend_layout_default_root by MapDelegate()
    override val system_depend_spacing by MapDelegate()

    override val system_depend_action_search by MapDelegate()
    override val system_depend_action_new by MapDelegate()
    override val system_depend_action_edit by MapDelegate()
    override val system_depend_action_delete by MapDelegate()
    override val system_depend_action_delete_confirm_title by MapDelegate()
    override val system_depend_action_config by MapDelegate()
    override val system_depend_action_import by MapDelegate()
    override val system_depend_action_export by MapDelegate()
    override val system_depend_action_print by MapDelegate()
    override val system_depend_label_all by MapDelegate()

    override val sign_out by MapDelegate()
    override val sign_out_disclaimer by MapDelegate()
    override val sign_out_link by MapDelegate()

    override val mail_dialog_title by MapDelegate()
    override val mail_to by MapDelegate()
    override val mail_from by MapDelegate()
    override val mail_subject by MapDelegate()
    override val mail_body by MapDelegate()
    override val mail_send by MapDelegate()

}

