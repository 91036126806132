package csaware.systemdepend

import api.FieldType
import api.SystemDependencyField
import api.SystemDependencyResource
import csaware.main.CsawareServices
import csaware.messages.csawareMessageStrategy
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.*
import kafffe.core.*

class SystemResourceChangeDlg(systemResource: SystemDependencyResource,
                              stateTxt: String,
                              val connectedFrom: List<String>) :
        FormDialog<SystemDependencyResource>(Model.of("${systemResource.name}: $stateTxt"), Model.of(systemResource)) {

    init {
        labelStrategy = csawareMessageStrategy("system_depend_")
    }

    private val resourceIds = CsawareServices.systemDependencies.model.data.sortedBy(SystemDependencyResource::name).map { it.id }

    // Map functional submodel list to mutable list of ids
    private val connectsToModel: Model<List<String>> = model.func(
            { p -> p.data.source.toList() },
            { p, v -> p.data.source = v.toMutableList() }
    )

    private val connectsTo = object : FormGroupMultipleEditSelect<String>("connectedTo", labelStrategy.label("connectedTo"), connectsToModel, Model.of(resourceIds)) {
        override fun display(choice: String): String =
                CsawareServices.systemDependencies.byId(choice)?.name ?: ""
    }

    /**
     * Model of connected from ids that can edited, but are to berepresented by conncetsTo on the other System Dependency Resources
     */
    val connectsFromModel: Model<List<String>> = Model.of(connectedFrom)

    private val connectsFrom = object : FormGroupMultipleEditSelect<String>("connectedFrom", labelStrategy.label("connectedFrom"), connectsFromModel, Model.of(resourceIds)) {
        override fun display(choice: String): String =
                CsawareServices.systemDependencies.byId(choice)?.name ?: ""
    }

    private val infoflowModel: Model<List<String>> = model.func({ p -> p.data.x_infoflow.toList() }, { p, v -> p.data.x_infoflow = v.toMutableList() })
    private val infoflow = FormGroupMultipleEditSelectString("infoFlow", labelStrategy.label("infoFlow"), infoflowModel, Model.of(CsawareServices.systemDependencies.config.data.getValueSet(SystemDependencyResource.infoflowValueSet).toList()))

    init {
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "75vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "1600px"
        })
        modal.modifiersContent.add(CssClassModifier("bg-info text-light"))
        // We need some hgap because we do not apply whitespace "\n" between buttons.
        row {
            col(ColWidth(ResponsiveSize.md, 6)) {
                readonly(SystemDependencyResource::id)
                input(SystemDependencyResource::name)
                addChild(connectsFrom)
                addChild(connectsTo)
                addChild(infoflow)
                // Key Values
                group {
                    addClass("row")
                    val config = CsawareServices.systemDependencies.config.data
                    for (field in config.fields) {
                        val hasValueSet = field.valueSet != null
                        val single = field.cardinality.isSingle
                        when {
                            single && !hasValueSet -> {
                                val valueModel: Model<String> = fieldValueModel(field)
                                if (field.type == FieldType.MARKDOWN) {
                                    textArea(field.id, Model.of(field.label), valueModel).apply {
                                        addClass("row col-sm-6")
                                        modifiersInput.add(CssClassModifier("col-sm-8"))
                                        modifiersLabel.add(CssClassModifier("col-sm-4"))
                                    }
                                } else {
                                    input(field.id, Model.of(field.label), valueModel).apply {
                                        addClass("row col-sm-6")
                                        modifiersInput.add(CssClassModifier("col-sm-8"))
                                        modifiersLabel.add(CssClassModifier("col-sm-4"))
                                        if (field.type == FieldType.SECRET) {
                                            inputType = "password"
                                        }
                                    }
                                }
                            }
                            single && hasValueSet -> {
                                val valueModel: Model<String?> = fieldOptionalValueModel(field)
                                val valueList = FormGroupSingleEditSelectString(field.id, Model.of(field.label), valueModel, Model.of(CsawareServices.systemDependencies.config.data.getValueSet(field.valueSet!!).toList()))
                                valueList.apply {
                                    modifiers.add(CssClassModifier("row col-sm-6"))
                                    modifiersInput.add(CssClassModifier("col-sm-8"))
                                    modifiersLabel.add(CssClassModifier("col-sm-4"))
                                }
                                addChild(valueList)
                            }
                            !single && !hasValueSet-> {
                                val valuesModel: Model<List<String>> = model.func(
                                        getData =  { p -> p.data.dataLists[field.id]?.toList() ?: listOf() },
                                        setData =  { p, v -> p.data.dataLists[field.id] = v.toMutableList()
                                        })
                                val valueList = FormGroupMultipleEdit(field.id, Model.of(field.label), valuesModel)
                                valueList.apply {
                                    modifiers.add(CssClassModifier("row col-sm-6"))
                                    modifiersInput.add(CssClassModifier("col-sm-8"))
                                    modifiersLabel.add(CssClassModifier("col-sm-4"))
                                }
                                addChild(valueList)
                            }
                            !single && hasValueSet -> {
                                val valuesModel: Model<List<String>> = model.func(
                                        getData =  { p -> p.data.dataLists[field.id]?.toList() ?: listOf() },
                                        setData =  { p, v -> p.data.dataLists[field.id] = v.toMutableList()
                                        })
                                val valueList = FormGroupMultipleEditSelectString(field.id, Model.of(field.label), valuesModel, Model.of(CsawareServices.systemDependencies.config.data.getValueSet(field.valueSet!!).toList()))
                                valueList.apply {
                                    modifiers.add(CssClassModifier("row col-sm-6"))
                                    modifiersInput.add(CssClassModifier("col-sm-8"))
                                    modifiersLabel.add(CssClassModifier("col-sm-4"))
                                }
                                addChild(valueList)
                            }
                        }
                    }
                }

            }
            col(ColWidth(ResponsiveSize.md, 6)) {
                textArea(SystemDependencyResource::description).apply {
                    lines = 18
                }
            }
        }

        submit().apply {
            color = BasicColor.primary
            addClass("mr-2") // space to the right
        }
        cancel().color = BasicColor.secondary
    }

    private fun fieldValueModel(field: SystemDependencyField): FunctionalSubModel<String, SystemDependencyResource> {
        fun getData(p: Model<SystemDependencyResource>): String = p.data.data[field.id] ?: ""
        fun setData(p: Model<SystemDependencyResource>, value: String) {
            if (value.isBlank()) {
                p.data.data.remove(field.id)
            } else {
                p.data.data[field.id] = value
            }
        }
        return model.func(::getData, ::setData)
    }

    private fun fieldOptionalValueModel(field: SystemDependencyField): FunctionalSubModel<String?, SystemDependencyResource> {
        fun getData(p: Model<SystemDependencyResource>): String? = p.data.data[field.id]
        fun setData(p: Model<SystemDependencyResource>, value: String?) {
            if (value.isNullOrBlank()) {
                p.data.data.remove(field.id)
            } else {
                p.data.data[field.id] = value
            }
        }
        return model.func(::getData, ::setData)
    }

}


