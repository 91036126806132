package csaware.main

import api.SystemDependencyConfig
import api.SystemDependencyResource
import csaware.About
import csaware.Signout
import csaware.comm.CSAwareBackend
import csaware.infoshare.InformationShareView
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.overview.ThreatDashBoard
import csaware.systemdepend.SystemDependencies
import csaware.threats.ThreatsTableCurrentActive
import csaware.threats.ThreatsTableRecentClosed
import csaware.translation.AutoTranslationModel
import csaware.user.UserManagement
import dk.rheasoft.csaware.api.UserRole
import kafffe.bootstrap.*
import kafffe.bootstrap.navigation.Nav
import kafffe.bootstrap.navigation.NavContainer
import kafffe.core.*
import kafffe.messages.Messages
import kafffe.messages.NavLanguageSelector
import kafffe.messages.i18nText
import kafffe.messages.messagesModel
import kotlin.browser.window

private var infoItem: NavContainer.Item? = null
private var infoCount: Int = 0

fun updateInfoCount(count: Int) {
    infoCount = count
    infoItem?.rerender()
}

fun updateInfoCount() {
    CsawareServices.backend.getInformationShareCount(::updateInfoCount)
}

private lateinit var rootNavigation: NavigationElement

fun navigateTo(path: String) {
    rootNavigation.navigateTo(NavigationPath.fromString(path))
}

fun init() {
    val alerts = AlertsPanel()
    val autoTranslationModel = AutoTranslationModel
    ServiceRegistry.register("alerts", alerts)
    CsawareServices.initServices()
    UserInformation.loadCurrent {
        CsawareServices.systemDependencies.expireModels()
        UserInformation.expireModels()
        //preload systemgraph and config
        with(CsawareServices.systemDependencies) {
            val config: SystemDependencyConfig = config.data
            val graph: List<SystemDependencyResource> = model.data
        }
        rootNavigation = NavigationElement.create("root") {
            if (UserInformation.hasAnyRole(UserRole.SystemAdministrator, UserRole.Viewer)) {
                component("pie") { ThreatDashBoard() }
                // component("config") {Label(Model.of("Configuration comes here"))}
                component("threats") { ThreatsTableCurrentActive() }
                component("threatsClosed") { ThreatsTableRecentClosed() }
                component("systemDependencies") { path: NavigationPath ->
                    SystemDependencies()
                            .apply {
                                if (!path.rest.empty) {
                                    val nodeName = path.rest.head
                                     CsawareServices.systemDependencies.byName(nodeName)?.let {
                                         graph.selectionModel.data = it
                                         loadSelectedThreats()
                                         graph.panToSelection()
                                     }
                                }
                            }
                }


            }
            if (UserInformation.hasRole(UserRole.SystemAdministrator)) {
                component("infoshare") { InformationShareView() }
            }
            if (UserInformation.hasRole(UserRole.UserAdministrator)) {
                component("users") { UserManagement() }
            }
            component("about") { About() }
            component("signout") { Signout() }
        }
        BootstrapRoot().apply {
            val container = BootstrapContainer.fluid()
            rootNavigation.componentConsumer = { container.replaceContent(it) }
            addChild(Nav.create(rootNavigation) {
                addExpand(ResponsiveSize.xl)
                style = ColorStrength.light
                // backgroundColor = "#b08c23 !important"
                background = BasicColor.secondary
                brand("", "images/BrandLogo.png")
                toggle("menuToggle")
                toggleBlock("menuToggle") {
                    if (UserInformation.hasAnyRole(UserRole.SystemAdministrator, UserRole.Viewer)) {
                        item(i18nText(CsawareMessages::nav_overview), NavigationPath.fromString("root/pie"), "fas fa-chart-pie")
                        item(i18nText(CsawareMessages::nav_threats), NavigationPath.fromString("root/threats"), "fas fa-exclamation-triangle")
                        item(i18nText(CsawareMessages::nav_threatsClosed), NavigationPath.fromString("root/threatsClosed"), "fas fa-check")
                    }
                    if (UserInformation.hasAnyRole(UserRole.SystemAdministrator, UserRole.Viewer)) {
                        item(i18nText(CsawareMessages::system_dependencies), NavigationPath.fromString("root/systemDependencies"), "fas fa-network-wired")
                    }
                    if (UserInformation.hasRole(UserRole.SystemAdministrator)) {
                        infoItem = item(i18nText(CsawareMessages::informationshare_management), NavigationPath.fromString("root/infoshare"), "fas fa-info")
                        infoItem?.modifiers?.add(HtmlElementModifier.create {
                            if (infoCount > 0) {
                                appendChild(
                                        KafffeHtml.start.sup {
                                            addClass("ml-1 badge badge-pill badge-warning")
                                            element?.style?.fontSize = "0.7rem"
                                            text(infoCount.toString())
                                        }.element!!
                                )
                            }
                        }
                        )
                    }
                    if (UserInformation.hasRole(UserRole.UserAdministrator)) {
                        item(i18nText(CsawareMessages::user_management), NavigationPath.fromString("root/users"), "fas fa-users")
                    }
//                  item(i18nText(CsawareMessages::nav_config), NavigationPath.fromString("root/config"), "fas fa-cog")
                    addChild(NavLanguageSelector(i18nText(Messages::languageSelect))).also { langSelect ->
                        langSelect.addDivider()
                        langSelect.addChild(AutoTranslationMenuItem(autoTranslationModel))
                    }
                    item(i18nText(Messages::nav_about), NavigationPath.fromString("root/about"), "fas fa-question")
                }
                item(Model.ofGet { UserInformation.current.email }, NavigationPath.fromString("root/signout"), "fas fa-sign-out-alt").apply { addClass("mr-auto") }
            })
            addChild(alerts)
            addChild(container)
            // rerender with autotranslation or messages changes
            messagesModel.listeners.add(ModelChangeListener { container.rerenderRecursive() })
            autoTranslationModel.listeners.add(ModelChangeListener { container.rerenderRecursive() })
            attach()
        }
        if (UserInformation.hasAnyRole(UserRole.SystemAdministrator, UserRole.Viewer)) {
            rootNavigation.navigateTo(NavigationPath.fromString("root/pie"))
        } else if (UserInformation.hasAnyRole(UserRole.UserAdministrator)) {
            rootNavigation.navigateTo(NavigationPath.fromString("root/users"))
        } else {
            rootNavigation.navigateTo(NavigationPath.fromString("root/about"))
            window.alert("Contact your CS-AWARE User Administration in order to gain access")
        }
    }

    CSAwareBackend.updateListeners.add(mainOnServerUpdate)
    updateInfoCount()
}



private val mainOnServerUpdate: (String) -> Unit = {
    if (it.contains("InformationShare")) {
        updateInfoCount()
    }
}

fun main(args: Array<String>) {
    println("Csaware Start")
}

