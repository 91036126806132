package csaware.systemdepend

import api.SystemDependencyConfig
import api.SystemDependencyResource
import csaware.main.CsawareServices
import kafffe.core.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import kotlin.browser.window
import kotlin.dom.addClass
import kotlin.dom.removeClass

class SystemSearch(val selectedResourceModel: Model<SystemDependencyResource>, resourcesModel: Model<List<SystemDependencyResource>>) : KafffeComponentWithModel<List<SystemDependencyResource>>(resourcesModel) {
    private var inputElement: HTMLInputElement? = null
    private var dropdownElement: HTMLDivElement? = null

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                addClass("sd_search_bar")
                inputElement = input {
                    addClass("sd_search_input bg-info text-white")
                    withElement {
                        type = "text"
                        onfocus = {
                            it
                        }
                        onblur = {
                            // delay display none to after click in selection, otherwise the click seems to be lost
                            window.setTimeout({ dropdownElement?.style?.display = "none" }, 400);
                            it
                        }
                        oninput = {
                            renderMatches()
                        }
                        onkeydown = {
                            when (it.key) {
                                "Escape" -> inputElement?.blur()
                                "Enter" -> inputElement?.blur()
                                "ArrowDown" -> selectNext()
                                "ArrowUp" -> selectPrev()
                            }
                            it
                        }
                    }
                }.element
                button {
                    onclick {
                        inputElement?.focus()
                    }
                    addClass("sd_search_icon btn")
                    i {
                        addClass("fas fa-search")
                    }
                }
                dropdownElement = div {
                    addClass("sd_dropdown bg-info")
                }.element
            }

    val maxMatches: Int = 7
    var selectIndex: Int = -1
    fun selectNext() {
        if (maxMatches >= selectIndex + 1) {
            ++selectIndex
        }
        select(selectIndex)
    }

    fun selectPrev() {
        if (selectIndex > 0) {
            --selectIndex
        }
        select(selectIndex)
    }

    fun select(index: Int) {
        val matches = matches()
        if (index in 0 until matches.size) {
            selectedResourceModel.data = matches[index]
            // set and remove "sd_selected" class
            dropdownElement?.children?.asList()?.forEachIndexed { i, element ->
                if (i == index) {
                    element.addClass("sd_selected")
                } else {
                    element.removeClass("sd_selected")
                }
            }
        }
    }

    fun matches(): List<SystemDependencyResource> {
        val txt = inputElement?.value ?: ""
        return if (txt.length > 1) {
            model.data.filter { it.name.contains(txt, ignoreCase = true) } //
                    .union(model.data.filter { it.description.contains(txt, ignoreCase = true) }) //
                    .union(model.data.filter {
                        val fieldValues = it.data.values + it.dataLists.values.flatten()
                        fieldValues.find { it.contains(txt, ignoreCase = true) } != null
                    }) //
                    .take(maxMatches).toList()
        } else {
            listOf()
        }
    }

    fun renderMatches() {
        selectIndex = -1
        dropdownElement?.innerHTML = ""
        val htmlConsumer = KafffeHtml(dropdownElement)
        val matches = matches()

        for (sdr in matches) {
            val item =
                    htmlConsumer.div {
                        addClass("sd_dropdown_item")
                        val txt = inputElement!!.value
                        h4 { addHighlightMatch(txt, sdr.name) }
                        val textLength = 70
                        val descTxt =
                                if (sdr.description.contains(txt, ignoreCase = true)) {
                                    val i = sdr.description.indexOf(txt, ignoreCase = true)
                                    if (i < 10) sdr.description.take(textLength) + " ..." else "... " + (sdr.description.substring(i - 10).take(textLength)) + " ..."
                                } else {
                                    sdr.description.take(textLength) + " ..."
                                }
                        div { addHighlightMatch(txt, descTxt) }
                        val singleField =  CsawareServices.systemDependencies.config.data.fields.find { sdr.data[it.id]?.let{it.contains(txt, ignoreCase = true)} ?: false};
                        if (singleField != null) {
                            div {
                                strong { +singleField.label }
                                text(": ")
                                addHighlightMatch(txt, sdr.data[singleField.id]!!)
                            }
                        } else {
                            val multiField =  CsawareServices.systemDependencies.config.data.fields.find { field ->
                                sdr.dataLists[field.id]?.let{ values -> values.find { it.contains(txt, ignoreCase = true)} != null} ?: false
                            }
                            if (multiField != null) {
                                div {
                                    strong { +multiField.label }
                                    text(": ")
                                    addHighlightMatch(txt, sdr.dataLists[multiField.id]!!.joinToString(", "))
                                }
                            }
                        }
                    }
            item.element?.onclick = {
                selectedResourceModel.data = sdr
                inputElement?.blur()
                it
            }
        }
        dropdownElement?.style?.display = if (matches.isEmpty()) "none" else "block"
    }

    private fun KafffeHtml<out HTMLElement>.addHighlightMatch(tobeMatched: String, txt: String) {
        if (txt.contains(tobeMatched, ignoreCase = true)) {
            val startIx = txt.indexOf(tobeMatched, ignoreCase = true)
            text(txt.substring(0,startIx))
            span {
                addClass("bg-success")
                text(txt.substring(startIx, startIx + tobeMatched.length))
            }
            text(txt.substring(startIx + tobeMatched.length))
        } else {
            text(txt)
        }
    }

}