package csaware.translation

import api.TranslationText
import csaware.comm.CSAwareBackend
import csaware.messages.CsawareMessages

object StandardTranslation {
    fun translate(text: String, receiver: (translation: String) -> Unit) {
        if (AutoTranslationModel.data && CsawareMessages.get().language != "en" ) {
            CSAwareBackend(::exceptionHandler).translate(TranslationText("en", CsawareMessages.get().language, text)) {
                receiver(it.text)
            }
        } else {
            receiver(text)
        }
    }

    private fun exceptionHandler(status: Int, msg: String) {
        println("Translation error: $status: $msg")

    }
}