package csaware.systemdepend

import api.SystemDependencyResource
import dk.rheasoft.csaware.api.UserRole
import dk.rheasoft.csaware.api.timestampNow
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.systemdepend.config.SystemResourceConfigChangeDlg
import csaware.systemdepend.config.SystemResourceImportDlg
import kafffe.bootstrap.Modal
import kafffe.core.Model
import kotlin.browser.window

enum class UiFunctions(val singleResource: Boolean, val label: String, val iconCls: String, val roles: Set<UserRole>) {

    edit(true, CsawareMessages.get().system_depend_action_edit, "fas fa-edit", setOf(UserRole.SystemAdministrator)) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            if (currentResource.id.isNotEmpty()) {
                SystemResourceChangeDlg(currentResource, CsawareMessages.get().system_depend_action_edit, CsawareServices.systemDependencies.connectedFrom(currentResource)).apply {
                    onSubmitOk = {
                        val sr = this.model.data
                        CsawareServices.systemDependencies.store(sr, selectedModel)
                        updateConnectedFrom(sr)
                    }
                    attach()
                }
            }
        }
    },
    delete(true, CsawareMessages.get().system_depend_action_delete, "fas fa-trash", setOf(UserRole.SystemAdministrator)) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            if (currentResource.id.isNotEmpty()) {
                Modal.confirm(i18nText(CsawareMessages::system_depend_action_delete_confirm_title), Model.of("${currentResource.name}")) {
                    for (other in CsawareServices.systemDependencies.model.data) {
                        if (other.source.contains(currentResource.id)) {
                            other.source.remove(currentResource.id)
                            CsawareServices.systemDependencies.store(other, Model.of(other))
                        }
                    }
                    CsawareServices.systemDependencies.delete(currentResource, selectedModel)
                }
            }
        }

    },
    create(true, CsawareMessages.get().system_depend_action_new, "far fa-plus-square", setOf(UserRole.SystemAdministrator)) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            val id = generateUuid()
            val from = if (!currentResource.id.isEmpty()) {
                mutableListOf<String>(currentResource.id)
            } else {
                mutableListOf()
            }
            val resource = SystemDependencyResource(id, "", timestampNow(), timestampNow(), "Some new resource", from)
            SystemResourceChangeDlg(resource, CsawareMessages.get().system_depend_action_new, connectedFrom = from).apply {
                onSubmitOk = {
                    val sr = this.model.data
                    CsawareServices.systemDependencies.store(sr, selectedModel)
                    updateConnectedFrom(sr)
                }
                attach()
            }
        }
    },
    config(false, CsawareMessages.get().system_depend_action_config, "fas fa-wrench", setOf(UserRole.SystemAdministrator)) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            SystemResourceConfigChangeDlg(CsawareServices.systemDependencies.config.data).apply {
                attach()
            }
        }
    },
    export(false, CsawareMessages.get().system_depend_action_export, "fas fa-file-export", setOf(UserRole.SystemAdministrator)) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            window.parent.open("/sysdep/export")
        }
    },
    import(false, CsawareMessages.get().system_depend_action_import, "fas fa-file-import", setOf(UserRole.SystemAdministrator)) {
        override fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>) {
            SystemResourceImportDlg().apply {
                onSubmitOk = {
                    val jsonData = fileData.data
                    CsawareServices.systemDependencies.import(jsonData, replaceCurrent = true)
                }
                attach()
            }
        }
    }
    ;

    companion object {
        val resourceFunctions = values().filter { it.singleResource }
        val globalFunctions = values().filter { !it.singleResource }
        public fun generateUuid(prefix: String = "identity--"): String {
            val ran = js("""(""+1e7+-1e3+-4e3+-8e3+-1e11).replace(/1|0/g,function(){return(0|Math.random()*16).toString(16)})""")
            return "$prefix$ran"
        }
    }

    protected fun SystemResourceChangeDlg.updateConnectedFrom(sr: SystemDependencyResource) {
        for (other in CsawareServices.systemDependencies.model.data) {
            val hasConnectTo = (other.source.contains(sr.id))
            val nowConnectTo = connectsFromModel.data.contains(other.id)
            if (hasConnectTo && !nowConnectTo) {
                other.source.remove(sr.id)
                CsawareServices.systemDependencies.store(other, Model.of(other))
            }
            if (!hasConnectTo && nowConnectTo) {
                other.source.add(sr.id)
                CsawareServices.systemDependencies.store(other, Model.of(other))
            }

        }
    }

    abstract fun doIt(currentResource: SystemDependencyResource, selectedModel: Model<SystemDependencyResource>)

}