package csaware.infoshare

import csaware.comm.CSAwareBackend
import csaware.main.CsawareServices
import csaware.main.updateInfoCount
import csaware.messages.CsawareMessages
import csaware.messages.i18nText
import csaware.threats.Popovers
import csaware.translation.StandardTranslation
import dk.rheasoft.csaware.api.InformationShare
import dk.rheasoft.csaware.api.QueryResult
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.messages.Messages.Companion.formatDateTime
import kotlin.dom.addClass
import kotlin.math.ceil

class InformationShareView : KafffeComponent() {
    val titleLabel = addChild(Label(i18nText(CsawareMessages::informationshare_management)))

    val table = addChild(BootstrapTable.create<InformationShare>(listOf()) {
        rowClickHandler = this@InformationShareView::showInformationShareDlg
        //TODO show tooltip when user hoover cursor over header.

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover bg-secondary text-white"))
        col(i18nText(CsawareMessages::id), { Label(it.id) })
        col(i18nText(CsawareMessages::informationshare_create), { Label(it.created.let { it.formatDateTime() }) })
        col(i18nText(CsawareMessages::informationshare_modified), { Label(it.modified.let { it.formatDateTime() }) })
        col(i18nText(CsawareMessages::informationshare_state), { Label(CsawareMessages.get().shareState(it.state)) })

        col(i18nText(CsawareMessages::description), {
            val label = Label(it.summary)
            StandardTranslation.translate(it.summary) {translation ->
                label.text = translation
            }
            label
        })
    })

    var pageSize: Int = 10

    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager).apply {
        addClass("card-footer csaware-field text-light")
        addClass("m-0")
        addClass("pl-2")
    })

    private val onServerUpdate: (String) -> Unit = { loadData() }

    override fun attach() {
        super.attach()
        CSAwareBackend.updateListeners.add(onServerUpdate)
    }

    override fun detach() {
        CSAwareBackend.updateListeners.remove(onServerUpdate)
        super.detach()
    }

    init {
        pager.changeListeners.add({ loadData() })
        loadData()
    }

    fun loadData() {
        CsawareServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        CsawareServices.backend.getInformationShares(offset, pageSize, this::receiveData)
    }

    private fun receiveData(response: QueryResult<InformationShare>) {
        updateInfoCount(response.nofResult)

        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    fun showInformationShareDlg(share: InformationShare) {
        InformationShareEditDlg(share).apply {
            onSubmitOk = {
                CsawareServices.alerts.clearAlerts()
                CsawareServices.backend.updateInformationShare(model.data) {
                    loadData()
                    rerenderRecursive()
                }
            }
            attach()
        }
    }

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                div {
                    addClass("card mt-2 bg-secondary")
                    div {
                        addClass("h4")
                        addClass("card-header csaware-field text-light")
                        add(titleLabel.html.also { it.addClass("mr-auto") })
                        val popover = Popovers.showInfo(i18nText(CsawareMessages::informationshare_management).data)
                            { i18nText(CsawareMessages::informationshare_short_description).data }
                        popover.modify(this.element!!)
                    }
                    add(table.html)
                    add(paginator.html)
                }
            }

}