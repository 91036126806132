package csaware.threats

import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.KeyboardEvent
import kotlin.browser.window
import kotlin.math.max
import kotlin.math.min

class ColumnHeaderDropdownFilter(val headerLabelModel: Model<String>, model: Model<String>, val noFilterValue: String, val choiceModel: Model<List<String>>) : KafffeComponentWithModel<String>(model) {
    private var dropdownElement: HTMLDivElement? = null
    private var choicesContainer: HTMLDivElement? = null
    private var filterInput: HTMLInputElement? = null

    private var selIndex = -1


    private fun keydownHandling(it: KeyboardEvent) {
        when (it.key) {
            "Escape" -> model.data = model.data
            "Enter" -> model.data = filteredChoices()[selIndex]
            "ArrowDown" -> selectNext()
            "ArrowUp" -> selectPrev()
        }
        it.stopPropagation()
    }

    private fun openDropdown() {
        dropdownElement?.apply {
            style.display = "block"
            println("block->  ${style.display}")
        }
        filterInput?.focus()
    }

    private fun closeDropdown() {
        dropdownElement?.apply {
            style.display = "none"
            println("none-> ${style.display}")
        }
    }

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
                selIndex = choiceModel.data.indexOf(model.data)
                withElement {
                    style.position = "relative"
                    style.cursor = "pointer"
                    tabIndex = 0
                    onfocus = { openDropdown() }
                    onclick = {
                        openDropdown()
                        it.stopPropagation()
                    }
                }
                span {
                    text(headerLabelModel.data)
                }
                i {
                    addClass("fas fa-filter")
                    addClass(if (noFilterValue == model.data) "text-info" else "text-warning")
                    element?.style?.cssFloat = "right"
                }
                dropdownElement = div {
                    addClass("sd_dropdown bg-info")
                    withElement {
                        style.display = "none"
                        style.minWidth = "16rem"
                    }
                    div {
                        addClass("sd_dropdown_item")
                        filterInput = input {
                            addClass("form-control")
                            withElement {
                                type = "text"
                                value = choiceFilter
                                oninput = {
                                    choiceFilter = value
                                    dropDownChildren()
                                }
                                onblur = { window.setTimeout(closeDropdown(), 100) }
                                onkeydown = ::keydownHandling
                                onclick = {
                                    focus()
                                    it.stopPropagation()
                                }
                            }
                        }.element
                    }
                    choicesContainer = div {

                    }.element
                }.element
                dropDownChildren()
            }

    var choiceFilter = ""

    private fun dropDownChildren() {
        choicesContainer?.innerHTML = ""
        val kafffeDrop = KafffeHtml(choicesContainer)
        val choices = filteredChoices()
        if (selIndex >= choices.size) {
            selIndex = choices.size - 1
        }
        kafffeDrop.div {
            choices.forEachIndexed { index, choice ->
                div {
                    addClass("sd_dropdown_item")
                    if (selIndex == index) {
                        addClass("sd_selected")
                    }
                    text(choice)
                    element?.onclick = {
                        model.data = choice
                        it
                    }
                }
            }
        }
    }

    private fun filteredChoices() =
            choiceModel.data.filter { choice -> choiceFilter.isBlank() || choice.startsWith(choiceFilter, true) }

    private fun selectNext() {
        selIndex = min(choiceModel.data.size - 1, selIndex + 1)
        updateSelection()
    }

    private fun selectPrev() {
        selIndex = max(0, selIndex - 1)
        updateSelection()
    }

    private fun updateSelection() {
        // could be optimized to only move "sd_selected" class
        dropDownChildren()
    }

}