package csaware.systemdepend.config

import api.*
import csaware.main.CsawareServices
import csaware.messages.CsawareMessages
import csaware.messages.csawareMessageStrategy
import csaware.messages.i18nText
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ModalSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.FormGroupMultipleEditSelect
import kafffe.bootstrap.form.checkbox
import kafffe.core.*

class SystemResourceConfigChangeDlg(systemResource: SystemDependencyConfig) :
        FormDialog<SystemDependencyConfig>(Model.of("System Dependency Config"), Model.of(cloneData(systemResource))) {

    private val layoutRootModel: Model<List<String>> = model.func(
            { p -> p.data.rootNodeIds.toList() },
            { p, v -> p.data.rootNodeIds = v.toMutableList() }
    )
    private val resourceIds = CsawareServices.systemDependencies.model.data.sortedBy(SystemDependencyResource::name).map { it.id }
    private val layoutRoot = object : FormGroupMultipleEditSelect<String>("layout_roots", i18nText(CsawareMessages::system_depend_layout_roots), layoutRootModel, Model.of(resourceIds)) {
        override fun display(choice: String): String =
                CsawareServices.systemDependencies.byId(choice)?.name ?: ""
    }

    private enum class Tab(val label: Model<String>) {
        Layout(i18nText(CsawareMessages::system_depend_layout)),
        Fields(i18nText(CsawareMessages::system_depend_fields)),
        ValueSets(i18nText(CsawareMessages::system_depend_fields_valueset)),
        NodeTypeToShape(i18nText(CsawareMessages::system_depend_fields_to_shape_mapping))
    }

    private var currentTab: Tab = Tab.Layout

    private fun selectTab(newTab: Tab) {
        if (newTab != currentTab) {
            // validate -> update model with previous tab data
            processForm(
                    onOk = {
                        currentTab = newTab
                        rerenderRecursive()
                    },
                    onError = {

                    })
        }
    }

    init {
        labelStrategy = csawareMessageStrategy("system_depend_")
        size = ModalSize.large
        modal.modifiersBody.add(StyleModifier {
            overflowY = "auto"
            maxHeight = "80vh"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "90vw"
            width = "60rem"
        })
        modal.modifiersContent.add(CssClassModifier("bg-info text-light"))

        addChild(NavTabs())

        val layoutEditor = group {
            addClass("col-md-6")
            inputNum(SystemDependencyConfig::spacing).apply {
                minimum = 20
                maximum = 200
            }
            addChild(layoutRoot)
            val horizontalModel = model.func(
                    getData = { it.data.layoutDirection.isHorizontal() },
                    setData = { m, v -> m.data.layoutDirection = if (v) LayoutDirection.horizontal else LayoutDirection.vertical }
            )
            checkbox("direction", horizontalModel, labelStrategy.label("layout_horizontal"))
        }
        layoutEditor.modifyStyle { display = if (currentTab == Tab.Layout) "block" else "none" }

        val fieldsEditor = FieldsEditor(model.property(SystemDependencyConfig::fields), model)
        fieldsEditor.modifyStyle { display = if (currentTab == Tab.Fields) "block" else "none" }
        addChild(fieldsEditor)

        val valueSetsEditor = ValueSetsEditor(model.property(SystemDependencyConfig::valueSets), countFunction = ::countFieldsUsingValueSet, valueCountFunction = ::countValueUse)
        valueSetsEditor.modifyStyle { display = if (currentTab == Tab.ValueSets) "block" else "none" }
        addChild(valueSetsEditor)

        val mapSetEditor = TypeToShapeMapEditor(model.property(SystemDependencyConfig::shapeMap), model)
        mapSetEditor.modifyStyle { display = if (currentTab == Tab.NodeTypeToShape) "block" else "none" }
        addChild(mapSetEditor)

        submit().apply {
            color = BasicColor.primary
            addClass("mr-2") // space to the right
        }
        cancel().color = BasicColor.secondary

        onSubmitOk = {
            CsawareServices.systemDependencies.storeConfig(model.data)
        }
    }

    inner class NavTabs : KafffeComponent() {
        override fun KafffeHtmlBase.kafffeHtml() = ul {
            addClass("nav nav-tabs")
            for (tab in Tab.values()) {
                li {
                    addClass("nav-item")
                    a {
                        addClass("nav-link")
                        if (tab == currentTab) {
                            addClass("active")
                        }
                        text(tab.label.data)
                        element?.onclick = {
                            selectTab(tab)
                            it.preventDefault()
                        }
                    }
                }
            }
        }

    }

    private fun countFieldsUsingValueSet(): Map<String, Int> =
            model.data.fields.map { it.valueSet }.filterNotNull().groupingBy { it }.eachCount()

    private fun countValueUse(valueSetName: String): Map<String, Int> {
        // collect fields using valueset
        val fieldIds = model.data.fields.filter { valueSetName == it.valueSet }.map { it.id }
        // collect values from fields
        val fieldUse = CsawareServices.systemDependencies.model.data;
        val values = fieldUse.flatMap { it.data.filterKeys { it in fieldIds }.values }
        val valuesFromLists = fieldUse.flatMap { it.dataLists.filterKeys { it in fieldIds }.values }.flatMap { it }
        // include predefined fields
        val valuesFromPredef = when (valueSetName) {
            SystemDependencyResource.infoflowValueSet -> fieldUse.flatMap { it.x_infoflow }
            else -> listOf()
        }

        // count
        return (values + valuesFromLists + valuesFromPredef).groupingBy { it }.eachCount()
    }

}

private fun cloneData(systemResource: SystemDependencyConfig) = parseSystemDependencyConfig(systemResource.toJson())


